
import React from 'react';
import EnvironmentDebugger from '@/components/EnvironmentDebugger';
import FileCacheDebugger from '@/components/FileCacheDebugger';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

const EnvDebugPage: React.FC = () => {
  return (
    <div className="container mx-auto py-8">
      <h1 className="text-3xl font-bold mb-6">Environment Configuration</h1>
      <p className="text-muted-foreground mb-6">
        This page helps you inspect and compare environment configurations across deployments.
        Use this to diagnose issues between development, QA, and production environments.
      </p>

      <Tabs defaultValue="environment">
        <TabsList className="mb-6">
          <TabsTrigger value="environment">Environment</TabsTrigger>
          <TabsTrigger value="cache">Cache Detection</TabsTrigger>
        </TabsList>
        
        <TabsContent value="environment">
          <EnvironmentDebugger />
        </TabsContent>
        
        <TabsContent value="cache">
          <div className="space-y-6">
            <p className="text-sm text-muted-foreground">
              The cache debugger helps identify if you're seeing cached files rather than the latest deployed version.
              Check the timestamps of critical files to ensure you're seeing the most recent deployment.
            </p>
            <FileCacheDebugger />
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default EnvDebugPage;
