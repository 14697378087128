
import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { RefreshCw, FileText, AlertCircle } from 'lucide-react';

/**
 * FileCacheDebugger - A component that helps detect caching issues by displaying 
 * file modification timestamps and version information
 */
export const FileCacheDebugger: React.FC = () => {
  const [fileTimestamps, setFileTimestamps] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [refreshKey, setRefreshKey] = useState(0);

  // Get file modification timestamps
  useEffect(() => {
    const getFileTimestamps = async () => {
      setIsLoading(true);
      setError(null);
      
      try {
        // Get document last modified date
        const htmlModified = document.lastModified;
        
        // Function to get timestamp for a file
        const getFileTimestamp = async (url: string) => {
          try {
            const response = await fetch(url, { method: 'HEAD' });
            if (!response.ok) {
              return 'Error: ' + response.status;
            }
            return response.headers.get('last-modified') || 'Unknown';
          } catch (err) {
            console.error(`Error fetching ${url}:`, err);
            return 'Error';
          }
        };
        
        // Get timestamps for critical files
        const [
          mainBundle,
          cssBundle,
          environmentModule,
          buildManifest
        ] = await Promise.all([
          getFileTimestamp('/assets/index.js'),
          getFileTimestamp('/assets/index.css'),
          getFileTimestamp('/src/environments/environment.ts'), // This will likely 404 but worth trying
          getFileTimestamp('/build-manifest.json')
        ]);
        
        setFileTimestamps({
          htmlDocument: htmlModified,
          mainBundle,
          cssBundle,
          environmentModule, 
          buildManifest
        });
      } catch (err) {
        console.error('Error fetching file timestamps:', err);
        setError('Failed to fetch file timestamps');
      } finally {
        setIsLoading(false);
      }
    };
    
    getFileTimestamps();
  }, [refreshKey]);
  
  // Handle refresh button
  const handleRefresh = () => {
    setRefreshKey(prev => prev + 1);
  };
  
  // Format date for display
  const formatDate = (dateString: string) => {
    if (!dateString || dateString.includes('Error')) return dateString;
    try {
      return new Date(dateString).toLocaleString();
    } catch (e) {
      return dateString;
    }
  };
  
  return (
    <Card>
      <CardHeader>
        <div className="flex items-center justify-between">
          <CardTitle className="text-lg">File Cache Debugger</CardTitle>
          <Button 
            variant="outline" 
            size="sm" 
            onClick={handleRefresh}
            disabled={isLoading}
          >
            <RefreshCw className={`h-4 w-4 mr-1 ${isLoading ? 'animate-spin' : ''}`} />
            Refresh
          </Button>
        </div>
        <CardDescription>
          Check file modification timestamps to detect caching issues
        </CardDescription>
      </CardHeader>
      <CardContent>
        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertCircle className="h-4 w-4 mr-2" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        
        <div className="space-y-4">
          <div className="grid gap-2">
            <div className="flex items-center justify-between py-2 border-b">
              <div className="flex items-center">
                <FileText className="h-4 w-4 mr-2 text-muted-foreground" />
                <span>HTML Document</span>
              </div>
              <div className="text-sm">
                {isLoading ? (
                  <Badge variant="outline">Loading...</Badge>
                ) : (
                  formatDate(fileTimestamps.htmlDocument)
                )}
              </div>
            </div>
            
            <div className="flex items-center justify-between py-2 border-b">
              <div className="flex items-center">
                <FileText className="h-4 w-4 mr-2 text-muted-foreground" />
                <span>Main JavaScript Bundle</span>
              </div>
              <div className="text-sm">
                {isLoading ? (
                  <Badge variant="outline">Loading...</Badge>
                ) : (
                  formatDate(fileTimestamps.mainBundle)
                )}
              </div>
            </div>
            
            <div className="flex items-center justify-between py-2 border-b">
              <div className="flex items-center">
                <FileText className="h-4 w-4 mr-2 text-muted-foreground" />
                <span>CSS Bundle</span>
              </div>
              <div className="text-sm">
                {isLoading ? (
                  <Badge variant="outline">Loading...</Badge>
                ) : (
                  formatDate(fileTimestamps.cssBundle)
                )}
              </div>
            </div>
            
            <div className="flex items-center justify-between py-2 border-b">
              <div className="flex items-center">
                <FileText className="h-4 w-4 mr-2 text-muted-foreground" />
                <span>Build Manifest</span>
              </div>
              <div className="text-sm">
                {isLoading ? (
                  <Badge variant="outline">Loading...</Badge>
                ) : (
                  formatDate(fileTimestamps.buildManifest)
                )}
              </div>
            </div>
          </div>
          
          <Alert className="mt-4">
            <AlertCircle className="h-4 w-4 mr-2" />
            <AlertDescription>
              <p className="text-sm mb-2">
                If these timestamps don't match your recent code changes, you may be seeing a cached version.
              </p>
              <ol className="list-decimal list-inside text-xs space-y-1">
                <li>Try clearing your browser cache</li>
                <li>Look for a "Clear cache and hard reload" option in browser dev tools</li>
                <li>Use a private/incognito window</li>
                <li>Try using the "force-clean-deploy.js" script to force a clean build</li>
              </ol>
            </AlertDescription>
          </Alert>
        </div>
      </CardContent>
    </Card>
  );
};

export default FileCacheDebugger;
