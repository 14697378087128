
import { supabase } from "@/integrations/supabase/client";
import { PDLCompany } from "./types";
import { toast } from "@/hooks/use-toast";

/**
 * Search for companies in the local database and PDL API
 */
export const searchCompanies = async (
  query: string, 
  userId: string | undefined
): Promise<{ localCompanies: PDLCompany[], pdlCompanies: PDLCompany[] }> => {
  console.log("[searchCompanies] Starting search for:", query);
  
  // Initialize results
  const results = {
    localCompanies: [] as PDLCompany[],
    pdlCompanies: [] as PDLCompany[]
  };
  
  try {
    // 1. Search local database first (if user is logged in)
    if (userId) {
      console.log("[searchCompanies] Searching local DB for user:", userId);
      const { data: localData, error: localError } = await supabase
        .from('companies')
        .select('*')
        .ilike('name', `%${query}%`)
        .limit(5);
      
      if (localError) {
        console.error("[searchCompanies] Local database search error:", localError);
      } else if (localData && localData.length > 0) {
        console.log("[searchCompanies] Found local companies:", localData.length);
        results.localCompanies = localData.map(company => ({
          id: company.id,
          name: company.name,
          display_name: company.display_name || company.name,
          website: company.website || '',
          industry: company.industry || '',
          location: company.location || '',
          source: 'local'
        }));
      } else {
        console.log("[searchCompanies] No local companies found");
      }
    }
    
    // 2. Search PDL API using the company_autocomplete function
    if (query.trim().length >= 2) {
      console.log("[searchCompanies] Calling PDL API via Edge Function");
      console.log("[searchCompanies] Request payload:", { query });
      
      // Call the edge function - using POST method with query in the body
      try {
        const functionResponse = await supabase.functions.invoke('company_autocomplete', {
          method: 'POST',
          body: { query: query.trim() }  // Ensure no extra whitespace in the query
        });
        
        console.log("[searchCompanies] PDL API Edge Function response:", functionResponse);
        
        if (functionResponse.error) {
          console.error("[searchCompanies] PDL API Edge Function error:", functionResponse.error);
          throw functionResponse.error;
        }
        
        if (functionResponse.data && functionResponse.data.companies && Array.isArray(functionResponse.data.companies)) {
          results.pdlCompanies = functionResponse.data.companies;
          console.log("[searchCompanies] PDL companies found (POST):", results.pdlCompanies.length);
        } else {
          console.warn("[searchCompanies] Invalid or empty PDL response structure:", functionResponse.data);
        }
      } catch (postError) {
        console.error("[searchCompanies] POST method failed, trying GET:", postError);
        
        // Try with GET method as a fallback
        try {
          // Use the correct invocation format for GET requests
          const cleanQuery = encodeURIComponent(query.trim());
          console.log("[searchCompanies] Encoded query for GET request:", cleanQuery);
          
          // Fix: Remove queryParams and append to URL instead, which is compatible with FunctionInvokeOptions
          const getResponse = await supabase.functions.invoke(
            `company_autocomplete?query=${cleanQuery}`, 
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              }
            }
          );
          
          console.log("[searchCompanies] PDL API GET method response:", getResponse);
          
          if (getResponse.error) {
            console.error("[searchCompanies] PDL API GET method error:", getResponse.error);
            throw getResponse.error;
          }
          
          if (getResponse.data && getResponse.data.companies && Array.isArray(getResponse.data.companies)) {
            results.pdlCompanies = getResponse.data.companies;
            console.log("[searchCompanies] PDL companies found (GET):", results.pdlCompanies.length);
          }
        } catch (getError) {
          console.error("[searchCompanies] Both POST and GET methods failed:", getError);
        }
      }
    } else {
      console.log("[searchCompanies] Query too short for PDL search, skipping");
    }

    return results;
  } catch (error) {
    console.error("[searchCompanies] Error searching companies:", error);
    return results;
  }
};

/**
 * Save a company to the database
 */
export const saveCompanyToDatabase = async (
  company: PDLCompany, 
  userId: string | undefined
): Promise<string | null> => {
  if (!userId) return null;
  
  try {
    // Check if company already exists in our database
    const { data: existingCompanies, error: checkError } = await supabase
      .from('companies')
      .select('id')
      .eq('name', company.name)
      .limit(1);
    
    if (checkError) throw checkError;
    
    if (existingCompanies && existingCompanies.length > 0) {
      // Company already exists, return its ID
      return existingCompanies[0].id;
    }
    
    // Company doesn't exist, save it
    const { data, error } = await supabase
      .from('companies')
      .insert({
        user_id: userId,
        name: company.name,
        display_name: company.display_name || company.name,
        industry: company.industry || null,
        website: company.website || null,
        location: company.location || null,
      })
      .select('id')
      .single();
    
    if (error) throw error;
    
    return data?.id || null;
  } catch (error: unknown) {
    const typedError = error as { message?: string };
    console.error("Error saving company:", error);
    toast({
      title: "Error saving company",
      description: typedError.message || "Failed to save company information",
      variant: "destructive",
    });
    return null;
  }
};

/**
 * Create a new company manually
 */
export const createNewCompany = async (
  company: PDLCompany,
  userId: string | undefined
): Promise<string | null> => {
  if (!userId) return null;
  
  try {
    // Check if a company with this name already exists
    const { data: existingCompanies, error: checkError } = await supabase
      .from('companies')
      .select('id')
      .ilike('name', company.name)
      .limit(1);
    
    if (checkError) throw checkError;
    
    if (existingCompanies && existingCompanies.length > 0) {
      // Company with this name already exists, return its ID
      return existingCompanies[0].id;
    }
    
    // Create new company in database
    const { data, error } = await supabase
      .from('companies')
      .insert({
        user_id: userId,
        name: company.name,
        display_name: company.display_name || company.name,
        industry: company.industry || null,
        website: company.website || null,
        location: company.location || null,
      })
      .select('id')
      .single();
    
    if (error) throw error;
    
    return data?.id || null;
  } catch (error: unknown) {
    const typedError = error as { message?: string };
    console.error("Error creating company:", error);
    throw typedError;
  }
};
