
import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { InfoIcon, AlertTriangleIcon, RefreshCwIcon, ClockIcon } from 'lucide-react';
import { environment, formatVersion } from '@/environments/index';

interface DeploymentInfo {
  timestamp: string;
  buildId?: string;
  commitSha?: string;
  buildUrl?: string;
}

const VersionDebugger = () => {
  const [buildInfo, setBuildInfo] = useState<DeploymentInfo | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [refreshCount, setRefreshCount] = useState(0);

  // Fetch build manifest for better debugging
  useEffect(() => {
    const fetchBuildManifest = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // Try to fetch the build manifest that was created during the build process
        const response = await fetch('/build-manifest.json');
        
        if (response.ok) {
          const data = await response.json();
          setBuildInfo({
            timestamp: data.buildDate || new Date().toISOString(),
            buildId: data.githubRunId || 'local',
            commitSha: data.githubSha || 'unknown',
            buildUrl: data.buildUrl || null
          });
        } else {
          // Fallback to basic information
          setBuildInfo({
            timestamp: environment.buildDate || new Date().toISOString()
          });
          setError('Could not load detailed build information');
        }
      } catch (err) {
        console.error('Error fetching build manifest:', err);
        setError('Failed to load build information');
        // Still set basic build info from environment
        setBuildInfo({
          timestamp: environment.buildDate || new Date().toISOString()
        });
      } finally {
        setLoading(false);
      }
    };

    fetchBuildManifest();
  }, [refreshCount]);

  // Format the date for display
  const formatDate = (dateString: string) => {
    try {
      return new Date(dateString).toLocaleString();
    } catch (e) {
      return dateString;
    }
  };

  const handleRefresh = () => {
    setRefreshCount(prev => prev + 1);
  };

  return (
    <Card className="overflow-hidden">
      <CardHeader className="bg-muted/50">
        <div className="flex items-center justify-between">
          <CardTitle className="text-lg">Version Deployment Debugger</CardTitle>
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={handleRefresh}
            disabled={loading}
          >
            <RefreshCwIcon className={`h-4 w-4 mr-1 ${loading ? 'animate-spin' : ''}`} />
            Refresh
          </Button>
        </div>
        <CardDescription>
          Diagnose version discrepancies between codebase and deployed application
        </CardDescription>
      </CardHeader>
      <CardContent className="pt-4">
        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertTriangleIcon className="h-4 w-4 mr-2" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        
        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2 border rounded-md p-3">
              <div className="text-sm font-medium text-muted-foreground">Environment File Version</div>
              <div className="flex items-center">
                <Badge className="mr-2">{environment.name}</Badge>
                <span className="text-lg font-semibold">{formatVersion(environment.version)}</span>
              </div>
              <div className="text-xs text-muted-foreground">Raw value: {environment.version}</div>
            </div>
            
            <div className="space-y-2 border rounded-md p-3">
              <div className="text-sm font-medium text-muted-foreground">Build Information</div>
              <div className="space-y-1">
                <div className="flex items-center text-sm">
                  <ClockIcon className="h-3.5 w-3.5 mr-1 text-muted-foreground" />
                  {buildInfo ? formatDate(buildInfo.timestamp) : 'Unknown'}
                </div>
                {buildInfo?.buildId && (
                  <div className="text-xs text-muted-foreground">
                    Build ID: {buildInfo.buildId}
                  </div>
                )}
                {buildInfo?.commitSha && (
                  <div className="text-xs text-muted-foreground">
                    Commit: {buildInfo.commitSha.substring(0, 7)}
                  </div>
                )}
              </div>
            </div>
          </div>
          
          <Alert className="bg-amber-50 border-amber-200">
            <InfoIcon className="h-4 w-4 text-amber-600" />
            <AlertDescription className="text-amber-800">
              <p>If you see a version mismatch, it could be due to:</p>
              <ul className="list-disc pl-5 mt-1 text-sm">
                <li>Cached builds in the deployment pipeline</li>
                <li>Build process not using the latest environment files</li>
                <li>Environment detection issues at runtime</li>
                <li>Deployment not completing successfully</li>
              </ul>
            </AlertDescription>
          </Alert>
          
          <div className="text-sm text-muted-foreground mt-4">
            <p>Fixing steps:</p>
            <ol className="list-decimal pl-5 space-y-1">
              <li>Verify your commit includes the updated version file</li>
              <li>Check the GitHub Actions build logs for errors</li>
              <li>Try forcing a clean rebuild in your CI/CD pipeline</li>
              <li>Clear browser cache or try in incognito mode</li>
            </ol>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default VersionDebugger;
