
import React from "react";
import { cn } from "@/lib/utils";

interface StatCardProps {
  title: string;
  value: number | string;
  description?: string;
  icon?: React.ReactNode;
  trend?: "up" | "down" | "neutral";
  className?: string;
}

const StatCard: React.FC<StatCardProps> = ({
  title,
  value,
  description,
  icon,
  trend,
  className,
}) => {
  return (
    <div
      className={cn(
        "bg-white rounded-lg p-6 border border-border/60 card-shadow",
        className
      )}
    >
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <p className="text-sm font-medium text-muted-foreground">{title}</p>
          <h3 className="text-2xl font-semibold tracking-tight">{value}</h3>
          {description && (
            <p className="text-sm text-muted-foreground">{description}</p>
          )}
        </div>
        {icon && (
          <div className="w-10 h-10 rounded-full bg-primary/10 flex items-center justify-center text-primary">
            {icon}
          </div>
        )}
      </div>
      {trend && (
        <div className="mt-4 flex items-center">
          <span
            className={cn(
              "text-xs font-medium px-2 py-1 rounded-full",
              trend === "up" && "bg-green-100 text-green-800",
              trend === "down" && "bg-red-100 text-red-800",
              trend === "neutral" && "bg-gray-100 text-gray-800"
            )}
          >
            {trend === "up" && "↑ Increasing"}
            {trend === "down" && "↓ Decreasing"}
            {trend === "neutral" && "→ Stable"}
          </span>
        </div>
      )}
    </div>
  );
};

export default StatCard;
