
import React from 'react';
import { Badge } from '@/components/ui/badge';

type RoleBadgeProps = {
  role: 'user' | 'admin';
  className?: string;
}

const RoleBadge: React.FC<RoleBadgeProps> = ({ role, className }) => {
  return (
    <Badge 
      className={className}
      variant={role === 'admin' ? 'default' : 'secondary'}
    >
      {role === 'admin' ? 'Admin' : 'User'}
    </Badge>
  );
};

export default RoleBadge;
