
import React from 'react';
import { getBuildInfo } from '@/integrations/supabase/client';
import { AlertCircle, CheckCircle, Info, Database } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { environment, formatVersion, getEnvironmentName } from '@/environments/index';

interface EnvironmentDetails {
  name: string;
  icon: React.ReactNode;
  variant: 'default' | 'secondary' | 'outline';
  description: string;
  projectId: string;
}

export function EnvironmentIndicator() {
  const buildInfo = getBuildInfo();
  const currentUrl = window.location.hostname;
  const envName = getEnvironmentName();
  
  // Extract project ID from Supabase URL
  const getProjectId = (): string => {
    const url = new URL(environment.supabaseUrl || '');
    return url.hostname.split('.')[0];
  };
  
  // Determine environment and set appropriate colors/icons
  const getEnvironmentDetails = (): EnvironmentDetails => {
    if (envName === 'production') {
      return {
        name: 'Production',
        icon: <CheckCircle className="h-3.5 w-3.5 mr-1" />,
        variant: 'default',
        description: `Version ${formatVersion(environment.version)} - ${currentUrl}`,
        projectId: getProjectId(),
      };
    } else if (envName === 'qa') {
      return {
        name: 'QA',
        icon: <Info className="h-3.5 w-3.5 mr-1" />,
        variant: 'secondary',
        description: `Version ${formatVersion(environment.version)} - ${currentUrl}`,
        projectId: getProjectId(),
      };
    } else {
      return {
        name: 'Development',
        icon: <AlertCircle className="h-3.5 w-3.5 mr-1" />,
        variant: 'outline',
        description: `Version ${formatVersion(environment.version)} - ${currentUrl}`,
        projectId: getProjectId(),
      };
    }
  };

  const details = getEnvironmentDetails();
  
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Badge 
            variant={details.variant} 
            className="ml-2 h-6 cursor-help"
          >
            {details.icon}
            {details.name}
          </Badge>
        </TooltipTrigger>
        <TooltipContent>
          <div className="text-xs">
            <p>{details.description}</p>
            <div className="flex items-center text-muted-foreground mt-1">
              <Database className="h-3 w-3 mr-1" />
              <span>Project: {details.projectId}</span>
            </div>
            <p className="text-muted-foreground mt-1">Built: {new Date(buildInfo.buildDate).toLocaleString()}</p>
          </div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export default EnvironmentIndicator;
