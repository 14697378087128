
import * as React from "react"
import { State } from "./types"
import { useToast } from "./use-toast-hook"

// Create a ToastContext to provide context for the ToasterProvider component
export const ToastContext = React.createContext<ReturnType<typeof useToast> | null>(null)

// ToasterProvider component
export function ToasterProvider({ children }: { children: React.ReactNode }) {
  const value = useToast()
  return React.createElement(ToastContext.Provider, { value }, children)
}
