
import { Environment } from './environment.types';

// Development environment configuration
export const environment: Environment = {
  production: false,
  name: 'development',
  version: '0.4.28-dev', // Updated version for test environment detection
  supabaseUrl: 'https://degmiwtlrruthkczyrqe.supabase.co',
  supabaseAnonKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRlZ21pd3RscnJ1dGhrY3p5cnFlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDExNDAzMjksImV4cCI6MjA1NjcxNjMyOX0.70rRv_vjRrs2SFRM2mEiovExPu9sSZ1Kup4GgupKLkg',
  apiUrl: 'https://degmiwtlrruthkczyrqe.supabase.co/functions/v1',
  buildDate: new Date().toISOString(), // Fresh timestamp for each build
  requireAuth: false,
  isTestEnvironment: typeof process !== 'undefined' && process.env?.NODE_ENV === 'test',
};
