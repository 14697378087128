import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HomeIcon, LayoutIcon, CheckSquareIcon, BuildingIcon, ShieldIcon } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useAuth } from '@/context/AuthContext';
import { supabase } from '@/integrations/supabase/client';
import { useIsMobile } from '@/hooks/use-mobile';

const MobileNavigation = () => {
  const location = useLocation();
  const { user, session } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const isMobile = useIsMobile();
  
  // Add debug logging for test environment
  if (process.env.NODE_ENV === 'test') {
    console.log('MobileNavigation Debug:', {
      user,
      session,
      pathname: location.pathname,
      isMobile,
      isAdmin
    });
  }

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!user) return;
      
      try {
        const { data, error } = await supabase.rpc('has_role', {
          _user_id: user.id,
          _role: 'admin'
        });
        
        if (error) throw error;
        setIsAdmin(!!data);
      } catch (error) {
        console.error('Error checking admin status:', error);
        setIsAdmin(false);
      }
    };

    checkAdminStatus();
  }, [user]);

  // Don't render on non-mobile devices or when not authenticated
  if (!isMobile || !user || location.pathname === '/auth') return null;

  return (
    <nav className="fixed bottom-0 left-0 right-0 border-t bg-background z-50 shadow-lg" data-testid="mobile-nav">
      <div className="flex justify-around items-center h-16">
        <NavItem 
          to="/" 
          icon={<HomeIcon className="h-5 w-5" />} 
          label="Home" 
          active={location.pathname === '/'} 
        />
        <NavItem 
          to="/kanban" 
          icon={<LayoutIcon className="h-5 w-5" />} 
          label="Jobs" 
          active={location.pathname === '/kanban'} 
        />
        <NavItem 
          to="/tasks" 
          icon={<CheckSquareIcon className="h-5 w-5" />} 
          label="Tasks" 
          active={location.pathname === '/tasks'} 
        />
        <NavItem 
          to="/companies" 
          icon={<BuildingIcon className="h-5 w-5" />} 
          label="Companies" 
          active={location.pathname === '/companies'} 
        />
        
        {isAdmin && (
          <NavItem 
            to="/admin/roles" 
            icon={<ShieldIcon className="h-5 w-5" />} 
            label="Admin" 
            active={location.pathname.startsWith('/admin')} 
          />
        )}
      </div>
    </nav>
  );
};

type NavItemProps = {
  to: string;
  icon: React.ReactNode;
  label: string;
  active: boolean;
};

const NavItem: React.FC<NavItemProps> = ({ to, icon, label, active }) => {
  return (
    <Link
      to={to}
      className={cn(
        "flex flex-col items-center justify-center w-full py-1",
        active 
          ? "text-primary" 
          : "text-muted-foreground hover:text-foreground"
      )}
    >
      {icon}
      <span className="text-xs mt-1">{label}</span>
    </Link>
  );
};

export default MobileNavigation;
