
import { Environment } from './environment.types';

// Production environment
export const environment: Environment = {
  production: true,
  name: 'production',
  apiUrl: 'https://httuddfmxzblyssvkysh.supabase.co',
  supabaseUrl: 'https://httuddfmxzblyssvkysh.supabase.co',
  supabaseAnonKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imh0dHVkZGZteHpibHlzc3ZreXNoIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDA4NTEwNTksImV4cCI6MjA1NjQyNzA1OX0.Bz-40X3U0YEClEUGWm3iiIYg_HUCipReHAVLNVKDAJo',
  version: '0.2.3',
  buildDate: new Date().toISOString(),
  requireAuth: true,
};
