
import * as React from "react"
import { State, Toast } from "./types"
import { dispatch, listeners } from "./toast-reducer"
import { toast } from "./toast-functions"
import { actionTypes } from "./types"

export function useToast() {
  const [state, setState] = React.useState<State>({ toasts: [] })

  React.useEffect(() => {
    listeners.push(setState)
    return () => {
      const index = listeners.indexOf(setState)
      if (index > -1) {
        listeners.splice(index, 1)
      }
    }
  }, [])

  // Add aria-live region for accessibility
  React.useEffect(() => {
    const ariaLiveRegion = document.createElement('div')
    ariaLiveRegion.setAttribute('aria-live', 'polite')
    ariaLiveRegion.setAttribute('aria-atomic', 'true')
    ariaLiveRegion.setAttribute('role', 'status')
    ariaLiveRegion.style.position = 'absolute'
    ariaLiveRegion.style.width = '1px'
    ariaLiveRegion.style.height = '1px'
    ariaLiveRegion.style.padding = '0'
    ariaLiveRegion.style.margin = '-1px'
    ariaLiveRegion.style.overflow = 'hidden'
    ariaLiveRegion.style.clip = 'rect(0, 0, 0, 0)'
    ariaLiveRegion.style.whiteSpace = 'nowrap'
    ariaLiveRegion.style.border = '0'
    ariaLiveRegion.id = 'toast-live-region'
    document.body.appendChild(ariaLiveRegion)

    return () => {
      document.body.removeChild(ariaLiveRegion)
    }
  }, [])

  // Update aria-live region when toasts change
  React.useEffect(() => {
    const liveRegion = document.getElementById('toast-live-region')
    if (liveRegion && state.toasts.length > 0) {
      const latestToast = state.toasts[0]
      liveRegion.textContent = `${latestToast.title || ''} ${latestToast.description || ''}`
    }
  }, [state.toasts])

  // Utility functions for quick access to common toast types
  const showError = React.useCallback((title: string, description?: string) => {
    return toast({
      title,
      description,
      variant: "destructive",
    })
  }, [])

  const showSuccess = React.useCallback((title: string, description?: string) => {
    return toast({
      title,
      description,
      variant: "default",
    })
  }, [])

  const showWarning = React.useCallback((title: string, description?: string) => {
    return toast({
      title,
      description,
      // Fix: Ensure variant matches the allowed types from UI toast component
      // Using "default" instead of "warning" to match the allowed types
      variant: "default",
    })
  }, [])
  
  // New utility for workflow status notifications
  const showWorkflowStatus = React.useCallback((status: 'success' | 'error' | 'warning' | 'info', 
                                                title: string, 
                                                description?: string) => {
    // Map status to variant - fixed to use only allowed variant types
    const variantMap = {
      'success': 'default',
      'error': 'destructive',
      'warning': 'default', // Changed from 'warning' to 'default'
      'info': 'default'
    } as const;
    
    return toast({
      title,
      description,
      variant: variantMap[status],
    })
  }, [])

  return {
    ...state,
    toast,
    dismiss: (toastId?: string) => dispatch({ type: actionTypes.DISMISS_TOAST, toastId }),
    showError,
    showSuccess,
    showWarning,
    showWorkflowStatus,
  }
}
