
import React from "react";
import { Building2, Globe, Briefcase, MapPin } from "lucide-react";
import { Button } from "@/components/ui/button";
import { PDLCompany } from "./types";

interface SelectedCompanyProps {
  company: PDLCompany;
  onChangeClick: () => void;
}

const SelectedCompany: React.FC<SelectedCompanyProps> = ({ company, onChangeClick }) => {
  // Debug the selected company
  console.log("Selected company:", company);
  
  return (
    <div className="flex justify-between items-center p-3 border rounded-md bg-secondary/10">
      <div className="flex items-center space-x-2">
        <Building2 className="h-5 w-5 text-primary" />
        <div>
          <p className="font-medium">{company.display_name || company.name}</p>
          <div className="flex flex-col text-xs text-muted-foreground">
            {company.industry && (
              <div className="flex items-center">
                <Briefcase className="mr-1 h-3 w-3" />
                <p>{company.industry}</p>
              </div>
            )}
            {company.website && (
              <div className="flex items-center">
                <Globe className="mr-1 h-3 w-3" />
                <p className="truncate max-w-[200px]">{company.website}</p>
              </div>
            )}
            {company.location && (
              <div className="flex items-center">
                <MapPin className="mr-1 h-3 w-3" />
                <p className="truncate max-w-[200px]">{company.location}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Button 
        variant="outline" 
        size="sm"
        onClick={onChangeClick}
      >
        Change
      </Button>
    </div>
  );
};

export default SelectedCompany;
