
import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import ApiDocsDownload from '@/components/ApiDocsDownload';
import { getApiBaseUrl } from '@/integrations/supabase/client';

const ApiDocs = () => {
  const apiBaseUrl = getApiBaseUrl();
  
  return (
    <div className="container mx-auto py-6 space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">API Documentation</h1>
        <ApiDocsDownload />
      </div>
      
      <Card className="border dark:border-slate-700 shadow-sm">
        <CardHeader className="bg-slate-50 dark:bg-slate-800 rounded-t-lg">
          <CardTitle>API Documentation for LadderingUp Extensions</CardTitle>
          <CardDescription className="dark:text-slate-300">
            This document outlines the API endpoints available for Chrome extension and Gmail add-on integrations.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-6 pt-6">
          {/* Base URL */}
          <section className="space-y-2">
            <h2 className="text-xl font-semibold">Base URL</h2>
            <p>All API calls should be made to:</p>
            <code className="bg-slate-100 dark:bg-slate-800 px-4 py-2 rounded block font-mono text-sm">
              {apiBaseUrl}
            </code>
          </section>
          
          {/* Authentication */}
          <section className="space-y-2">
            <h2 className="text-xl font-semibold">Authentication</h2>
            <p>Most endpoints require a user ID for authentication. This should be included in the request body as <code className="bg-slate-100 dark:bg-slate-800 px-1 rounded">userId</code>.</p>
          </section>
          
          {/* Endpoints */}
          <section className="space-y-4">
            <h2 className="text-xl font-semibold">Endpoints</h2>
            
            {/* 1. Import Job */}
            <div className="border rounded-lg p-4 space-y-3 dark:border-slate-700">
              <h3 className="text-lg font-medium">1. Import Job from External Source</h3>
              <p>Allows job information to be scraped from websites and imported into the LadderingUp platform.</p>
              <div>
                <p><strong>Endpoint:</strong> <code className="bg-slate-100 dark:bg-slate-800 px-1 rounded">/external_integrations/scrapejob</code></p>
                <p><strong>Method:</strong> <code className="bg-slate-100 dark:bg-slate-800 px-1 rounded">POST</code></p>
                <p><strong>CORS:</strong> Enabled for cross-origin requests</p>
              </div>
              
              <div>
                <p><strong>Request Example:</strong></p>
                <pre className="bg-slate-100 dark:bg-slate-800 p-3 rounded text-sm overflow-auto font-mono">
{`{
  "userId": "user-uuid-here",
  "jobData": {
    "title": "Job Title",
    "company": "Company Name",
    "location": "Job Location",
    "description": "Job Description",
    "url": "https://job-listing-url.com",
    "salary": "$100,000 - $120,000",
    "jobType": "Full-time",
    "postedDate": "2023-06-15",
    "source": "chrome_extension",
    "sourceDetails": {
      "pageUrl": "https://example.com/job-listing",
      "scrapedAt": "2023-06-16T14:30:00Z"
    }
  }
}`}
                </pre>
              </div>
              
              <div>
                <p><strong>Response Example:</strong></p>
                <pre className="bg-slate-100 dark:bg-slate-800 p-3 rounded text-sm overflow-auto font-mono">
{`{
  "success": true,
  "message": "Job successfully imported",
  "jobId": "generated-job-uuid"
}`}
                </pre>
              </div>
            </div>
            
            {/* 2. Email Note */}
            <div className="border rounded-lg p-4 space-y-3 dark:border-slate-700">
              <h3 className="text-lg font-medium">2. Add Email Note to Job</h3>
              <p>Allows email correspondence to be imported as a note attached to a job.</p>
              <div>
                <p><strong>Endpoint:</strong> <code className="bg-slate-100 dark:bg-slate-800 px-1 rounded">/external_integrations/emailnote</code></p>
                <p><strong>Method:</strong> <code className="bg-slate-100 dark:bg-slate-800 px-1 rounded">POST</code></p>
                <p><strong>CORS:</strong> Enabled for cross-origin requests</p>
              </div>
              
              <div>
                <p><strong>Request Example (attach to existing job):</strong></p>
                <pre className="bg-slate-100 dark:bg-slate-800 p-3 rounded text-sm overflow-auto font-mono">
{`{
  "userId": "user-uuid-here",
  "emailData": {
    "jobId": "existing-job-uuid",
    "emailSubject": "Interview Scheduled",
    "emailBody": "Content of the email...",
    "emailDate": "2023-06-16T10:30:00Z",
    "emailSender": "recruiter@company.com",
    "source": "gmail_addon"
  }
}`}
                </pre>
              </div>
              
              <div>
                <p><strong>Response Example:</strong></p>
                <pre className="bg-slate-100 dark:bg-slate-800 p-3 rounded text-sm overflow-auto font-mono">
{`{
  "success": true,
  "message": "Email note successfully added",
  "noteId": "generated-note-uuid",
  "jobId": "job-uuid"
}`}
                </pre>
              </div>
            </div>
            
            {/* 3. Company Lookup */}
            <div className="border rounded-lg p-4 space-y-3 dark:border-slate-700">
              <h3 className="text-lg font-medium">3. Company Lookup</h3>
              <p>Search for companies in both the local database and PDL API.</p>
              <div>
                <p><strong>Endpoint:</strong> <code className="bg-slate-100 dark:bg-slate-800 px-1 rounded">/external_integrations/company_lookup</code></p>
                <p><strong>Method:</strong> <code className="bg-slate-100 dark:bg-slate-800 px-1 rounded">POST</code></p>
                <p><strong>CORS:</strong> Enabled for cross-origin requests</p>
              </div>
              
              <div>
                <p><strong>Request Example:</strong></p>
                <pre className="bg-slate-100 dark:bg-slate-800 p-3 rounded text-sm overflow-auto font-mono">
{`{
  "userId": "user-uuid-here",  // Optional: if provided, will search local database
  "query": "Company Name"
}`}
                </pre>
              </div>
              
              <div>
                <p><strong>Response Example:</strong></p>
                <pre className="bg-slate-100 dark:bg-slate-800 p-3 rounded text-sm overflow-auto font-mono">
{`{
  "companies": [
    {
      "id": "company-id",
      "name": "Company Name",
      "display_name": "Company Display Name",
      "website": "https://company.com",
      "industry": "Technology",
      "location": "San Francisco, CA",
      "source": "local"
    },
    {
      "id": "pdl-company-id",
      "name": "PDL Company",
      "display_name": "PDL Company Inc.",
      "website": "https://pdlcompany.com",
      "industry": "Finance",
      "location": "New York, NY",
      "source": "pdl"
    }
  ]
}`}
                </pre>
              </div>
            </div>
            
          </section>
          
          {/* Error Handling */}
          <section className="space-y-2">
            <h2 className="text-xl font-semibold">Error Handling</h2>
            <p>All endpoints return appropriate HTTP status codes:</p>
            <ul className="list-disc list-inside">
              <li><code className="bg-slate-100 dark:bg-slate-800 px-1 rounded">200</code>: Success</li>
              <li><code className="bg-slate-100 dark:bg-slate-800 px-1 rounded">400</code>: Bad request (invalid parameters)</li>
              <li><code className="bg-slate-100 dark:bg-slate-800 px-1 rounded">401</code>: Unauthorized (invalid or missing user ID)</li>
              <li><code className="bg-slate-100 dark:bg-slate-800 px-1 rounded">404</code>: Endpoint not found</li>
              <li><code className="bg-slate-100 dark:bg-slate-800 px-1 rounded">500</code>: Server error</li>
            </ul>
            <p>Error responses follow this format:</p>
            <pre className="bg-slate-100 dark:bg-slate-800 p-3 rounded text-sm overflow-auto font-mono">
{`{
  "error": "Error description message"
}`}
            </pre>
          </section>
          
          {/* Implementation Example */}
          <section className="space-y-2">
            <h2 className="text-xl font-semibold">Implementation Example</h2>
            <p>Here's a simple example of how to use these endpoints in JavaScript:</p>
            <pre className="bg-slate-100 dark:bg-slate-800 p-3 rounded text-sm overflow-auto font-mono">
{`// Example: Import a job from Chrome extension
async function importJobFromWebpage(userId, jobData) {
  try {
    const response = await fetch('${apiBaseUrl}/external_integrations/scrapejob', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: userId,
        jobData: {
          title: jobData.title,
          company: jobData.company,
          location: jobData.location || "Remote/Unspecified",
          description: jobData.description || "No description provided",
          url: jobData.url,
          source: 'chrome_extension',
          sourceDetails: {
            pageUrl: window.location.href,
            scrapedAt: new Date().toISOString()
          }
        }
      })
    });
    
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error importing job:', error);
    throw error;
  }
}`}
            </pre>
          </section>
          
          {/* Additional Notes */}
          <section className="space-y-2">
            <h2 className="text-xl font-semibold">Additional Notes</h2>
            <ul className="list-disc list-inside">
              <li>All timestamps should be in ISO 8601 format (e.g., <code className="bg-slate-100 dark:bg-slate-800 px-1 rounded">2023-06-16T14:30:00Z</code>)</li>
              <li>The <code className="bg-slate-100 dark:bg-slate-800 px-1 rounded">source</code> field helps track where the data originated from (either <code className="bg-slate-100 dark:bg-slate-800 px-1 rounded">chrome_extension</code> or <code className="bg-slate-100 dark:bg-slate-800 px-1 rounded">gmail_addon</code>)</li>
              <li>For Chrome extension, including the source URL in <code className="bg-slate-100 dark:bg-slate-800 px-1 rounded">sourceDetails</code> is recommended</li>
              <li>Maximum email body length is 5000 characters; longer content will be truncated</li>
            </ul>
          </section>
        </CardContent>
      </Card>
    </div>
  );
};

export default ApiDocs;
