
import React from "react";
import { Task } from "@/types";
import { cn } from "@/lib/utils";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { Calendar, Trash2 } from "lucide-react";
import { Badge } from "@/components/ui/badge";

interface TaskItemProps {
  task: Task;
  onToggleComplete: () => void;
  onDelete: () => void;
  jobTitle?: string;
}

const TaskItem: React.FC<TaskItemProps> = ({ task, onToggleComplete, onDelete, jobTitle }) => {
  // Format date to be more readable
  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };
  
  // Check if task is overdue
  const isOverdue = () => {
    if (task.completed) return false;
    return new Date(task.dueDate) < new Date();
  };
  
  // Check if task is due today
  const isDueToday = () => {
    if (task.completed) return false;
    const today = new Date();
    const dueDate = new Date(task.dueDate);
    return dueDate.getDate() === today.getDate() &&
           dueDate.getMonth() === today.getMonth() &&
           dueDate.getFullYear() === today.getFullYear();
  };
  
  // Determine priority color
  const getPriorityColor = () => {
    switch (task.priority) {
      case 'high': return 'text-red-600';
      case 'medium': return 'text-yellow-600';
      case 'low': return 'text-green-600';
      default: return '';
    }
  };

  // Determine status badge color
  const getStatusBadge = () => {
    const status = task.completed ? "Done" : task.status || "To Do";
    
    switch (status) {
      case "Done": 
        return <Badge variant="outline" className="bg-green-100 text-green-800 hover:bg-green-100">Done</Badge>;
      case "In Progress":
        return <Badge variant="outline" className="bg-blue-100 text-blue-800 hover:bg-blue-100">In Progress</Badge>;
      case "Blocked":
        return <Badge variant="outline" className="bg-red-100 text-red-800 hover:bg-red-100">Blocked</Badge>;
      default:
        return <Badge variant="outline" className="bg-gray-100 text-gray-800 hover:bg-gray-100">To Do</Badge>;
    }
  };

  return (
    <div
      className={cn(
        "flex items-center justify-between p-3 rounded-md border transition-colors",
        task.completed ? "bg-muted/30 border-border/40" : "bg-white border-border/60",
        isOverdue() && !task.completed && "border-red-200 bg-red-50",
        isDueToday() && !task.completed && "border-yellow-200 bg-yellow-50"
      )}
    >
      <div className="flex items-start space-x-3">
        <Checkbox 
          checked={task.completed} 
          onCheckedChange={onToggleComplete}
          className="mt-1"
        />
        <div>
          <div className="flex items-center flex-wrap gap-2">
            <p className={cn(
              "font-medium",
              task.completed && "line-through text-muted-foreground"
            )}>
              {task.title}
            </p>
            {getStatusBadge()}
          </div>
          {jobTitle && (
            <p className="text-sm text-muted-foreground mt-1">
              <span className="font-medium">Job:</span> {jobTitle}
            </p>
          )}
          {task.description && (
            <p className="text-sm text-muted-foreground max-w-sm mt-1">
              {task.description}
            </p>
          )}
          <div className="flex items-center mt-1 space-x-2 text-xs">
            <span className={cn(
              "flex items-center",
              isOverdue() ? "text-red-600" : isDueToday() ? "text-yellow-600" : "text-muted-foreground"
            )}>
              <Calendar size={12} className="mr-1" />
              {isOverdue() ? "Overdue: " : isDueToday() ? "Due Today: " : "Due: "}
              {formatDate(task.dueDate)}
            </span>
            <span className={cn(
              "px-1.5 py-0.5 rounded-full capitalize",
              task.priority === 'high' && "bg-red-100 text-red-800",
              task.priority === 'medium' && "bg-yellow-100 text-yellow-800",
              task.priority === 'low' && "bg-green-100 text-green-800"
            )}>
              {task.priority}
            </span>
          </div>
        </div>
      </div>
      <Button 
        variant="ghost" 
        size="icon" 
        onClick={onDelete}
        className="h-8 w-8 text-muted-foreground hover:text-destructive"
      >
        <Trash2 size={16} />
      </Button>
    </div>
  );
};

export default TaskItem;
