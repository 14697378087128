import React, { createContext, useContext, useState, useEffect } from 'react';
import { Job, Task, JobStats, JobStatus, JobActivity, ActivityType, JobActivityDB } from '@/types';
import { supabase } from '@/integrations/supabase/client';
import { useAuth } from '@/context/AuthContext';
import { toast } from '@/hooks/use-toast';

interface JobContextType {
  jobs: Job[];
  tasks: Task[];
  stats: JobStats;
  selectedJob: Job | null;
  setSelectedJob: (job: Job | null) => void;
  addJob: (job: Omit<Job, 'id'>) => Promise<void>;
  updateJob: (job: Job) => Promise<void>;
  updateJobStatus: (jobId: string, newStatus: JobStatus) => Promise<void>;
  deleteJob: (jobId: string) => Promise<void>;
  addTask: (task: Omit<Task, 'id'>) => Promise<void>;
  updateTask: (task: Task) => Promise<void>;
  toggleTaskCompletion: (taskId: string) => Promise<void>;
  deleteTask: (taskId: string) => Promise<void>;
  getJobTasks: (jobId: string) => Task[];
  getJobActivities: (jobId: string) => JobActivity[];
  calculateStats: () => void;
  loading: boolean;
  refreshData: () => Promise<void>;
}

const defaultStats: JobStats = {
  totalJobs: 0,
  newJobs: 0,
  appliedJobs: 0,
  interviewingJobs: 0,
  negotiatingJobs: 0,
  offers: 0,
  declined: 0,
  tasksUpcoming: 0,
  tasksPastDue: 0,
};

const JobContext = createContext<JobContextType | undefined>(undefined);

export const JobProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [activities, setActivities] = useState<JobActivity[]>([]);
  const [stats, setStats] = useState<JobStats>(defaultStats);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  const refreshData = async () => {
    if (!user) return;
    
    setLoading(true);
    try {
      const { data: jobsData, error: jobsError } = await supabase
        .from('jobs')
        .select('*')
        .order('created_at', { ascending: false });
      
      if (jobsError) throw jobsError;
      
      const { data: tasksData, error: tasksError } = await supabase
        .from('tasks')
        .select('*')
        .order('due_date', { ascending: true });
      
      if (tasksError) throw tasksError;
      
      let activitiesData: any[] = [];
      
      try {
        const { data, error } = await supabase
          .from('job_activities')
          .select('*')
          .order('timestamp', { ascending: false });
          
        if (!error && data) {
          activitiesData = data;
        }
      } catch (activitiesError) {
        console.log("Activities error:", activitiesError);
        await createActivitiesTable();
      }
      
      const formattedJobs: Job[] = jobsData.map(job => ({
        id: job.id,
        title: job.title,
        company: job.company,
        logo: job.logo || undefined,
        location: job.location,
        salary: job.salary || undefined,
        description: job.description,
        status: job.status as JobStatus,
        postedDate: new Date(job.posted_date).toISOString(),
        appliedDate: job.applied_date ? new Date(job.applied_date).toISOString() : undefined,
        url: job.url || undefined,
        notes: job.notes || undefined,
        contactName: job.contact_name || undefined,
        contactEmail: job.contact_email || undefined,
        contactPhone: job.contact_phone || undefined,
      }));
      
      const formattedTasks: Task[] = tasksData.map(task => ({
        id: task.id,
        jobId: task.job_id,
        title: task.title,
        description: task.description || undefined,
        dueDate: new Date(task.due_date).toISOString(),
        completed: task.completed,
        priority: task.priority as 'low' | 'medium' | 'high',
      }));
      
      const formattedActivities: JobActivity[] = activitiesData.map(activity => ({
        id: activity.id,
        jobId: activity.job_id,
        type: activity.type as ActivityType,
        timestamp: new Date(activity.timestamp).toISOString(),
        message: activity.message,
        details: activity.details || undefined,
      })) || [];
      
      setJobs(formattedJobs);
      setTasks(formattedTasks);
      setActivities(formattedActivities);
      calculateStats(formattedJobs, formattedTasks);
    } catch (error: unknown) {
      const typedError = error as { message?: string };
      console.error('Error fetching data:', error);
      toast({
        title: "Error fetching data",
        description: typedError.message || "An unknown error occurred",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      refreshData();
    } else {
      setJobs([]);
      setTasks([]);
      setActivities([]);
      setStats(defaultStats);
      setSelectedJob(null);
      setLoading(false);
    }
  }, [user]);

  const createActivitiesTable = async () => {
    try {
      const { error: permissionError } = await supabase
        .from('jobs')
        .select('id')
        .limit(1);
      
      if (permissionError) {
        throw new Error("Insufficient permissions to create table");
      }
      
      const { error } = await supabase.rpc('create_job_activities_table');
      
      if (error) {
        console.error("Failed to create activities table:", error);
        toast({
          title: "Database setup required",
          description: "Please ask your administrator to create the job_activities table",
          variant: "destructive",
        });
      } else {
        toast({
          title: "Job activities tracking enabled",
          description: "Your job activities will now be tracked",
        });
      }
    } catch (error: any) {
      console.error("Error creating activities table:", error);
    }
  };

  const addActivity = async (
    jobId: string,
    type: ActivityType,
    message: string,
    details?: string
  ) => {
    if (!user) return;
    
    try {
      const activityData = {
        job_id: jobId,
        user_id: user.id,
        type,
        message,
        details,
        timestamp: new Date().toISOString(),
      };
      
      const { data: directData, error: directError } = await supabase
        .from('job_activities')
        .insert(activityData)
        .select();
      
      if (directError) {
        console.error("Failed to add activity:", directError);
        return;
      }
      
      if (directData && directData.length > 0) {
        const newActivity: JobActivity = {
          id: directData[0].id,
          jobId: directData[0].job_id,
          type: directData[0].type as ActivityType,
          timestamp: new Date(directData[0].timestamp).toISOString(),
          message: directData[0].message,
          details: directData[0].details || undefined,
        };
        
        setActivities([newActivity, ...activities]);
      } else {
        refreshData();
      }
    } catch (error: any) {
      console.error("Error adding activity:", error);
    }
  };

  const getJobActivities = (jobId: string) => {
    return activities.filter(activity => activity.jobId === jobId);
  };

  const calculateStats = (currentJobs = jobs, currentTasks = tasks) => {
    const newStats: JobStats = {
      totalJobs: currentJobs.length,
      newJobs: currentJobs.filter(job => job.status === 'new').length,
      appliedJobs: currentJobs.filter(job => job.status === 'applied').length,
      interviewingJobs: currentJobs.filter(job => job.status === 'interviewing').length,
      negotiatingJobs: currentJobs.filter(job => job.status === 'negotiating').length,
      offers: currentJobs.filter(job => job.status === 'offer').length,
      declined: currentJobs.filter(job => job.status === 'declined').length,
      tasksUpcoming: currentTasks.filter(task => !task.completed && new Date(task.dueDate) >= new Date()).length,
      tasksPastDue: currentTasks.filter(task => !task.completed && new Date(task.dueDate) < new Date()).length,
    };
    setStats(newStats);
  };

  const addJob = async (job: Omit<Job, 'id'>) => {
    if (!user) return;
    
    try {
      const dbJob = {
        user_id: user.id,
        title: job.title,
        company: job.company,
        logo: job.logo,
        location: job.location,
        salary: job.salary,
        description: job.description,
        status: job.status,
        posted_date: new Date(job.postedDate).toISOString(),
        applied_date: job.appliedDate ? new Date(job.appliedDate).toISOString() : null,
        url: job.url,
        notes: job.notes,
        contact_name: job.contactName,
        contact_email: job.contactEmail,
        contact_phone: job.contactPhone,
        company_id: job.companyId,
      };
      
      const { data, error } = await supabase
        .from('jobs')
        .insert(dbJob)
        .select()
        .single();
      
      if (error) throw error;
      
      const newJob: Job = {
        id: data.id,
        title: data.title,
        company: data.company,
        logo: data.logo || undefined,
        location: data.location,
        salary: data.salary || undefined,
        description: data.description,
        status: data.status as JobStatus,
        postedDate: new Date(data.posted_date).toISOString(),
        appliedDate: data.applied_date ? new Date(data.applied_date).toISOString() : undefined,
        url: data.url || undefined,
        notes: data.notes || undefined,
        contactName: data.contact_name || undefined,
        contactEmail: data.contact_email || undefined,
        contactPhone: data.contact_phone || undefined,
        companyId: data.company_id || undefined,
      };
      
      setJobs([newJob, ...jobs]);
      calculateStats([...jobs, newJob], tasks);
      
      await addActivity(
        newJob.id,
        'job_created',
        `Job created: ${newJob.title} at ${newJob.company}`
      );
      
      toast({
        title: "Job Added",
        description: `${newJob.title} at ${newJob.company} has been added`,
      });
    } catch (error: unknown) {
      const typedError = error as { message?: string };
      console.error('Error adding job:', error);
      toast({
        title: "Error adding job",
        description: typedError.message || "An unknown error occurred",
        variant: "destructive",
      });
    }
  };

  const updateJob = async (updatedJob: Job) => {
    if (!user) return;
    
    try {
      const originalJob = jobs.find(j => j.id === updatedJob.id);
      if (!originalJob) throw new Error("Job not found");
      
      const dbJob = {
        title: updatedJob.title,
        company: updatedJob.company,
        logo: updatedJob.logo,
        location: updatedJob.location,
        salary: updatedJob.salary,
        description: updatedJob.description,
        status: updatedJob.status,
        posted_date: new Date(updatedJob.postedDate).toISOString(),
        applied_date: updatedJob.appliedDate ? new Date(updatedJob.appliedDate).toISOString() : null,
        url: updatedJob.url,
        notes: updatedJob.notes,
        contact_name: updatedJob.contactName,
        contact_email: updatedJob.contactEmail,
        contact_phone: updatedJob.contactPhone,
        updated_at: new Date().toISOString(),
      };
      
      const { error } = await supabase
        .from('jobs')
        .update(dbJob)
        .eq('id', updatedJob.id);
      
      if (error) throw error;
      
      const updatedJobs = jobs.map(job => job.id === updatedJob.id ? updatedJob : job);
      setJobs(updatedJobs);
      
      if (selectedJob?.id === updatedJob.id) {
        setSelectedJob(updatedJob);
      }
      
      calculateStats(updatedJobs, tasks);
      
      const changes: string[] = [];
      
      if (originalJob.title !== updatedJob.title) changes.push("title");
      if (originalJob.company !== updatedJob.company) changes.push("company");
      if (originalJob.location !== updatedJob.location) changes.push("location");
      if (originalJob.salary !== updatedJob.salary) changes.push("salary");
      if (originalJob.description !== updatedJob.description) changes.push("description");
      if (originalJob.url !== updatedJob.url) changes.push("url");
      
      const contactChanged = (
        originalJob.contactName !== updatedJob.contactName ||
        originalJob.contactEmail !== updatedJob.contactEmail ||
        originalJob.contactPhone !== updatedJob.contactPhone
      );
      
      const notesChanged = originalJob.notes !== updatedJob.notes;
      
      const statusChanged = originalJob.status !== updatedJob.status;
      
      if (contactChanged) {
        await addActivity(
          updatedJob.id,
          'contact_updated',
          `Contact information updated for ${updatedJob.title}`,
          `Updated: ${[
            updatedJob.contactName ? 'name' : '',
            updatedJob.contactEmail ? 'email' : '',
            updatedJob.contactPhone ? 'phone' : ''
          ].filter(Boolean).join(', ')}`
        );
      }
      
      if (notesChanged) {
        await addActivity(
          updatedJob.id,
          'note_updated',
          `Notes updated for ${updatedJob.title}`
        );
      }
      
      if (statusChanged) {
        await addActivity(
          updatedJob.id,
          'status_change',
          `Status changed from ${originalJob.status} to ${updatedJob.status}`,
          `Job at ${updatedJob.company}`
        );
      }
      
      if (changes.length > 0 && !contactChanged && !notesChanged) {
        await addActivity(
          updatedJob.id,
          'job_updated',
          `Job updated: ${updatedJob.title}`,
          `Updated fields: ${changes.join(', ')}`
        );
      }
      
      toast({
        title: "Job Updated",
        description: `${updatedJob.title} has been updated`,
      });
    } catch (error: any) {
      console.error('Error updating job:', error);
      toast({
        title: "Error updating job",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  const updateJobStatus = async (jobId: string, newStatus: JobStatus) => {
    if (!user) return;
    
    try {
      const job = jobs.find(j => j.id === jobId);
      if (!job) throw new Error("Job not found");
      
      const oldStatus = job.status;
      
      const { error } = await supabase
        .from('jobs')
        .update({ 
          status: newStatus,
          updated_at: new Date().toISOString(),
          ...(newStatus === 'applied' && !jobs.find(j => j.id === jobId)?.appliedDate 
              ? { applied_date: new Date().toISOString() } 
              : {})
        })
        .eq('id', jobId);
      
      if (error) throw error;
      
      const updatedJobs = jobs.map(job => {
        if (job.id === jobId) {
          const updatedJob = { 
            ...job, 
            status: newStatus 
          };
          
          if (newStatus === 'applied' && !job.appliedDate) {
            updatedJob.appliedDate = new Date().toISOString();
          }
          
          return updatedJob;
        }
        return job;
      });
      
      setJobs(updatedJobs);
      
      if (selectedJob?.id === jobId) {
        setSelectedJob({
          ...selectedJob,
          status: newStatus,
          ...(newStatus === 'applied' && !selectedJob.appliedDate 
              ? { appliedDate: new Date().toISOString() } 
              : {})
        });
      }
      
      calculateStats(updatedJobs, tasks);
      
      await addActivity(
        jobId,
        'status_change',
        `Status changed from ${oldStatus} to ${newStatus}`,
        `Job: ${job.title} at ${job.company}`
      );
      
      if (newStatus === 'applied' && oldStatus !== 'applied') {
        await addActivity(
          jobId,
          'application_submitted',
          `Application submitted for ${job.title} at ${job.company}`,
          `Applied on ${new Date().toLocaleString()}`
        );
      }
      
      toast({
        title: "Status Updated",
        description: `Job status changed to ${newStatus}`,
      });
    } catch (error: any) {
      console.error('Error updating job status:', error);
      toast({
        title: "Error updating status",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  const deleteJob = async (jobId: string) => {
    if (!user) return;
    
    try {
      const jobToDelete = jobs.find(job => job.id === jobId);
      
      const { error } = await supabase
        .from('jobs')
        .delete()
        .eq('id', jobId);
      
      if (error) throw error;
      
      await supabase
        .from('job_activities')
        .delete()
        .eq('job_id', jobId);
      
      const updatedJobs = jobs.filter(job => job.id !== jobId);
      const updatedTasks = tasks.filter(task => task.jobId !== jobId);
      const updatedActivities = activities.filter(activity => activity.jobId !== jobId);
      
      setJobs(updatedJobs);
      setTasks(updatedTasks);
      setActivities(updatedActivities);
      
      if (selectedJob?.id === jobId) {
        setSelectedJob(null);
      }
      
      calculateStats(updatedJobs, updatedTasks);
      
      toast({
        title: "Job Deleted",
        description: jobToDelete ? `${jobToDelete.title} has been removed` : "Job has been removed",
      });
    } catch (error: any) {
      console.error('Error deleting job:', error);
      toast({
        title: "Error deleting job",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  const addTask = async (task: Omit<Task, 'id'>) => {
    if (!user) return;
    
    try {
      const relatedJob = jobs.find(job => job.id === task.jobId);
      
      const dbTask = {
        user_id: user.id,
        job_id: task.jobId,
        title: task.title,
        description: task.description,
        due_date: new Date(task.dueDate).toISOString(),
        completed: task.completed,
        priority: task.priority,
      };
      
      const { data, error } = await supabase
        .from('tasks')
        .insert(dbTask)
        .select()
        .single();
      
      if (error) throw error;
      
      const newTask: Task = {
        id: data.id,
        jobId: data.job_id,
        title: data.title,
        description: data.description || undefined,
        dueDate: new Date(data.due_date).toISOString(),
        completed: data.completed,
        priority: data.priority as 'low' | 'medium' | 'high',
      };
      
      const updatedTasks = [...tasks, newTask];
      setTasks(updatedTasks);
      calculateStats(jobs, updatedTasks);
      
      if (relatedJob) {
        await addActivity(
          task.jobId,
          'job_updated',
          `New task added: ${task.title}`,
          `For job: ${relatedJob.title} at ${relatedJob.company}`
        );
      }
      
      toast({
        title: "Task Added",
        description: `Task "${newTask.title}" has been added`,
      });
    } catch (error: unknown) {
      const typedError = error as { message?: string };
      console.error('Error adding task:', error);
      toast({
        title: "Error adding task",
        description: typedError.message || "An unknown error occurred",
        variant: "destructive",
      });
    }
  };

  const updateTask = async (updatedTask: Task) => {
    if (!user) return;
    
    try {
      const dbTask = {
        title: updatedTask.title,
        description: updatedTask.description,
        due_date: new Date(updatedTask.dueDate).toISOString(),
        completed: updatedTask.completed,
        priority: updatedTask.priority,
        updated_at: new Date().toISOString(),
      };
      
      const { error } = await supabase
        .from('tasks')
        .update(dbTask)
        .eq('id', updatedTask.id);
      
      if (error) throw error;
      
      const updatedTasks = tasks.map(task => task.id === updatedTask.id ? updatedTask : task);
      setTasks(updatedTasks);
      calculateStats(jobs, updatedTasks);
      
      toast({
        title: "Task Updated",
        description: `Task "${updatedTask.title}" has been updated`,
      });
    } catch (error: any) {
      console.error('Error updating task:', error);
      toast({
        title: "Error updating task",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  const toggleTaskCompletion = async (taskId: string) => {
    if (!user) return;
    
    try {
      const task = tasks.find(t => t.id === taskId);
      if (!task) throw new Error("Task not found");
      
      const newCompletedState = !task.completed;
      
      const { error } = await supabase
        .from('tasks')
        .update({ 
          completed: newCompletedState,
          updated_at: new Date().toISOString()
        })
        .eq('id', taskId);
      
      if (error) throw error;
      
      const updatedTasks = tasks.map(task => {
        if (task.id === taskId) {
          return { ...task, completed: newCompletedState };
        }
        return task;
      });
      
      setTasks(updatedTasks);
      calculateStats(jobs, updatedTasks);
    } catch (error: any) {
      console.error('Error toggling task completion:', error);
      toast({
        title: "Error updating task",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  const deleteTask = async (taskId: string) => {
    if (!user) return;
    
    try {
      const taskToDelete = tasks.find(task => task.id === taskId);
      
      const { error } = await supabase
        .from('tasks')
        .delete()
        .eq('id', taskId);
      
      if (error) throw error;
      
      const updatedTasks = tasks.filter(task => task.id !== taskId);
      setTasks(updatedTasks);
      calculateStats(jobs, updatedTasks);
      
      toast({
        title: "Task Deleted",
        description: taskToDelete ? `Task "${taskToDelete.title}" has been removed` : "Task has been removed",
      });
    } catch (error: any) {
      console.error('Error deleting task:', error);
      toast({
        title: "Error deleting task",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  const getJobTasks = (jobId: string) => {
    return tasks.filter(task => task.jobId === jobId);
  };

  return (
    <JobContext.Provider
      value={{
        jobs,
        tasks,
        stats,
        selectedJob,
        setSelectedJob,
        addJob,
        updateJob,
        updateJobStatus,
        deleteJob,
        addTask,
        updateTask,
        toggleTaskCompletion,
        deleteTask,
        getJobTasks,
        getJobActivities,
        calculateStats,
        loading,
        refreshData,
      }}
    >
      {children}
    </JobContext.Provider>
  );
};

export const useJobContext = () => {
  const context = useContext(JobContext);
  if (context === undefined) {
    throw new Error('useJobContext must be used within a JobProvider');
  }
  return context;
};
