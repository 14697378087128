
import { Environment } from './environment.types';

// QA environment - Sandbox Instance (synced with dev)
export const environment: Environment = {
  production: false,
  name: 'qa',
  apiUrl: 'https://degmiwtlrruthkczyrqe.supabase.co',
  supabaseUrl: 'https://degmiwtlrruthkczyrqe.supabase.co',
  supabaseAnonKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRlZ21pd3RscnJ1dGhrY3p5cnFlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDExNDAzMjksImV4cCI6MjA1NjcxNjMyOX0.70rRv_vjRrs2SFRM2mEiovExPu9sSZ1Kup4GgupKLkg',
  version: '0.4.28-qa',  // Updated to match latest dev version with qa suffix
  buildDate: new Date().toISOString(),
  requireAuth: false, // Keeping false to match dev
};

// QA environment validation - will display a console error if not loaded correctly
if (typeof window !== 'undefined') {
  const envContext = import.meta.env.VITE_ENVIRONMENT || import.meta.env.MODE;
  const hostname = window.location.hostname;
  const isQaHostname = hostname.includes('qa-') || hostname.includes('qa.') || hostname.includes('qa--');
  
  if ((isQaHostname && envContext !== 'qa') || (envContext === 'qa' && !isQaHostname && !hostname.includes('localhost'))) {
    console.error(
      '🚨 QA ENVIRONMENT VALIDATION ERROR 🚨\n',
      `Expected QA environment context but got ${envContext}\n`,
      `Current hostname: ${hostname}\n`,
      'This suggests the wrong environment file is being used!'
    );
  } else {
    console.log(`✅ QA environment validated (${environment.version})`);
  }
}
