import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { useJobContext } from "@/context/JobContext";
import { ChevronDown, Save, X } from "lucide-react";
import { toast } from "@/hooks/use-toast";
import CompanySearch from "./company-search";

interface CreateJobModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const FORM_STORAGE_KEY = "jobForm_sessionData";

const CreateJobModal: React.FC<CreateJobModalProps> = ({ open, onOpenChange }) => {
  const { addJob } = useJobContext();

  const [formData, setFormData] = useState({
    company: "",
    title: "",
    location: "",
    locationType: "On-site",
    jobType: "Full-time",
    salary: "",
    url: "",
    description: "",
    notes: "",
    companyId: "",
  });

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (open) {
      const savedFormData = sessionStorage.getItem(FORM_STORAGE_KEY);
      if (savedFormData) {
        try {
          const parsedData = JSON.parse(savedFormData);
          setFormData(parsedData);
        } catch (e) {
          console.error("Failed to parse saved form data:", e);
        }
      }
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      sessionStorage.setItem(FORM_STORAGE_KEY, JSON.stringify(formData));
    }
  }, [formData, open]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (open && (e.key === 'Delete' || e.key === 'Backspace')) {
        const activeElement = document.activeElement;
        const isInputFocused = activeElement instanceof HTMLInputElement || 
                               activeElement instanceof HTMLTextAreaElement ||
                               (activeElement instanceof HTMLElement && activeElement.isContentEditable);
        
        if (!isInputFocused && e.key === 'Backspace') {
          e.preventDefault();
        }
        
        if (e.key === 'Delete') {
          e.preventDefault();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value ?? '' }));
    
    if (errors[name]) {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const handleCompanySelect = (companyId: string, companyName: string) => {
    setFormData(prev => ({
      ...prev,
      company: companyName ?? '',
      companyId: companyId ?? ''
    }));
    
    if (errors.company) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors.company;
        return newErrors;
      });
    }
  };

  const setDropdownValue = (field: string, value: string) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    if (!formData.company.trim()) {
      newErrors.company = "Company is required";
    }

    if (!formData.title.trim()) {
      newErrors.title = "Position is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setSubmitting(true);
    try {
      const fullLocation = formData.location
        ? `${formData.location} (${formData.locationType})`
        : formData.locationType;

      await addJob({
        title: formData.title,
        company: formData.company,
        location: fullLocation,
        description: formData.description || "No description provided",
        status: "new",
        postedDate: new Date().toISOString(),
        salary: formData.salary || undefined,
        url: formData.url || undefined,
        notes: formData.notes || undefined,
        companyId: formData.companyId || undefined,
      });

      toast({
        title: "Job Added",
        description: `${formData.title} at ${formData.company} has been added`,
      });

      sessionStorage.removeItem(FORM_STORAGE_KEY);

      setFormData({
        company: "",
        title: "",
        location: "",
        locationType: "On-site",
        jobType: "Full-time",
        salary: "",
        url: "",
        description: "",
        notes: "",
        companyId: "",
      });
      
      onOpenChange(false);
    } catch (error: unknown) {
      const errorMessage = error instanceof Error 
        ? error.message 
        : "Failed to add job";
        
      toast({
        title: "Error adding job",
        description: errorMessage,
        variant: "destructive",
      });   
    } finally {
      setSubmitting(false);
    }
  };

  const handleCloseModal = (open: boolean) => {
    if (!open) {
      sessionStorage.removeItem(FORM_STORAGE_KEY);
      
      setFormData({
        company: "",
        title: "",
        location: "",
        locationType: "On-site",
        jobType: "Full-time",
        salary: "",
        url: "",
        description: "",
        notes: "",
        companyId: "",
      });
      
      onOpenChange(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={handleCloseModal}>
      <DialogContent className="sm:max-w-[600px] max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-xl">Add New Job Opportunity</DialogTitle>
        </DialogHeader>

        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="test-title" className="text-right">
              Position <span className="text-destructive">*</span>
            </Label>
            <Input
              id="test-title"
              name="title"
              className="col-span-3"
              value={formData.title ?? ''}
              onChange={handleChange}
              placeholder="Job title/position"
            />
            {errors.title && (
              <p className="col-start-2 col-span-3 text-destructive text-sm">
                {errors.title}
              </p>
            )}
          </div>

          <div className="grid grid-cols-4 items-start gap-4">
            <Label htmlFor="company" className="text-right pt-2">
              Company <span className="text-destructive">*</span>
            </Label>
            <div className="col-span-3">
              <CompanySearch 
                onCompanySelect={handleCompanySelect}
                initialCompanyName={formData.company}
              />
              {errors.company && (
                <p className="text-destructive text-sm mt-1">
                  {errors.company}
                </p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="test-location" className="text-right">
              Location
            </Label>
            <div className="col-span-2">
              <Input
                id="test-location"
                name="location"
                value={formData.location ?? ''}
                onChange={handleChange}
                placeholder="City, State or Country"
              />
            </div>
            <div className="col-span-1">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" className="w-full justify-between">
                    {formData.locationType}
                    <ChevronDown className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-[200px]">
                  {["On-site", "Hybrid", "Remote"].map((type) => (
                    <DropdownMenuItem
                      key={type}
                      onClick={() => setDropdownValue("locationType", type)}
                    >
                      {type}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="jobType" className="text-right">
              Type
            </Label>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" className="col-span-3">
                  {formData.jobType}
                  <ChevronDown className="ml-2 h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-[200px]">
                {["Full-time", "Part-time", "Contract", "Temporary", "Fractional", "Internship"].map((type) => (
                  <DropdownMenuItem
                    key={type}
                    onClick={() => setDropdownValue("jobType", type)}
                  >
                    {type}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="test-salary" className="text-right">
              Salary
            </Label>
            <Input
              id="test-salary"
              name="salary"
              className="col-span-3"
              value={formData.salary ?? ''}
              onChange={handleChange}
              placeholder="e.g. $50,000 - $70,000"
            />
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="test-url" className="text-right">
              URL
            </Label>
            <Input
              id="test-url"
              name="url"
              className="col-span-3"
              value={formData.url ?? ''}
              onChange={handleChange}
              placeholder="Job listing URL"
            />
          </div>

          <div className="grid grid-cols-4 items-start gap-4">
            <Label htmlFor="description" className="text-right pt-2">
              Description
            </Label>
            <textarea
              id="description"
              name="description"
              className="col-span-3 flex min-h-[120px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
              value={formData.description}
              onChange={handleChange}
              placeholder="Job description (can paste full job listing)"
            />
          </div>

          <div className="grid grid-cols-4 items-start gap-4">
            <Label htmlFor="notes" className="text-right pt-2">
              Notes
            </Label>
            <textarea
              id="notes"
              name="notes"
              className="col-span-3 flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
              value={formData.notes}
              onChange={handleChange}
              placeholder="Personal notes about this opportunity"
            />
          </div>
        </div>

        <DialogFooter className="flex justify-end gap-2">
          <Button 
            variant="outline" 
            onClick={() => handleCloseModal(false)}
            disabled={submitting}
          >
            <X className="mr-2 h-4 w-4" />
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit} 
            disabled={submitting}
          >
            {submitting ? (
              <>Saving...</>
            ) : (
              <>
                <Save className="mr-2 h-4 w-4" />
                Save Job
              </>
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateJobModal;
