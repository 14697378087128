import React, { useState, useRef, useEffect } from "react";
import { Command, CommandEmpty, CommandInput, CommandList } from "@/components/ui/command";
import { Loader2, PlusCircle, X } from "lucide-react";
import { useAuth } from "@/context/AuthContext";
import { toast } from "@/hooks/use-toast";
import { CompanySearchProps, PDLCompany } from "./types";
import CompanyItem from "./CompanyItem";
import SelectedCompany from "./SelectedCompany";
import { searchCompanies, saveCompanyToDatabase, createNewCompany } from "./companyService";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

const CompanySearch: React.FC<CompanySearchProps> = ({ onCompanySelect, initialCompanyName }) => {
  const [searchQuery, setSearchQuery] = useState(initialCompanyName ?? "");
  const [isSearching, setIsSearching] = useState(false);
  const [localCompanies, setLocalCompanies] = useState<PDLCompany[]>([]);
  const [pdlCompanies, setPdlCompanies] = useState<PDLCompany[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<PDLCompany | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isCreatingCompany, setIsCreatingCompany] = useState(false);
  const [newCompany, setNewCompany] = useState({
    name: '',
    website: '',
    location: '',
    industry: ''
  });
  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, []);

  const handleSearch = (value: string) => {
    setSearchQuery(value ?? "");
    
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    if (!value || value.trim().length < 2) {
      setLocalCompanies([]);
      setPdlCompanies([]);
      return;
    }

    searchTimeoutRef.current = setTimeout(async () => {
      setIsSearching(true);
      try {
        const results = await searchCompanies(value, user?.id);
        setLocalCompanies(results.localCompanies);
        setPdlCompanies(results.pdlCompanies);
        console.log("Search results:", results);
      } catch (error) {
        console.error("Error searching companies:", error);
        toast({
          title: "Search Error",
          description: "Error searching for companies",
          variant: "destructive",
        });
      } finally {
        setIsSearching(false);
      }
    }, 300);
  };

  const handleCompanySelect = async (company: PDLCompany) => {
    setSelectedCompany(company);
    setSearchQuery(company.name);
    console.log("Selected company:", company);
    
    if (company.source === 'pdl') {
      setIsSaving(true);
      try {
        const companyId = await saveCompanyToDatabase(company, user?.id);
        if (companyId) {
          onCompanySelect(companyId, company.name);
        }
      } finally {
        setIsSaving(false);
      }
    } else {
      onCompanySelect(company.id, company.name);
    }
  };

  const handleClearSelection = () => {
    setSelectedCompany(null);
    setSearchQuery("");
    onCompanySelect("", "");
  };

  const handleCreateNewCompany = () => {
    setIsCreatingCompany(true);
    setNewCompany({
      name: searchQuery,
      website: '',
      location: '',
      industry: ''
    });
  };

  const handleNewCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewCompany(prev => ({
      ...prev,
      [name]: value ?? ''
    }));
  };

  const handleCancelNewCompany = () => {
    setIsCreatingCompany(false);
  };

  const handleSaveNewCompany = async () => {
    if (!newCompany.name.trim()) {
      toast({
        title: "Error",
        description: "Company name is required",
        variant: "destructive",
      });
      return;
    }

    setIsSaving(true);
    try {
      const companyData: PDLCompany = {
        id: newCompany.name.toLowerCase().replace(/\s+/g, '-'),
        name: newCompany.name,
        display_name: newCompany.name,
        website: newCompany.website,
        location: newCompany.location,
        industry: newCompany.industry
      };
      
      const companyId = await createNewCompany(companyData, user?.id);
      
      if (companyId) {
        setSelectedCompany(companyData);
        onCompanySelect(companyId, companyData.name);
        setIsCreatingCompany(false);
        
        toast({
          title: "Company Created",
          description: `${companyData.name} has been created`,
        });
      }
    } catch (error: unknown) {
      const typedError = error as { message?: string };
      console.error("Error creating company:", error);
      toast({
        title: "Error creating company",
        description: typedError.message || "Failed to create company",
        variant: "destructive",
      });
    } finally {
      setIsSaving(false);
    }
  };

  if (isCreatingCompany) {
    return (
      <div className="w-full space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-base font-medium">Create New Company</h3>
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={handleCancelNewCompany}
          >
            <X className="h-4 w-4 mr-2" />
            Cancel
          </Button>
        </div>
        
        <div className="space-y-3">
          <div>
            <Label htmlFor="companyName">Company Name*</Label>
            <Input
              id="companyName"
              name="name"
              value={newCompany.name ?? ''}
              onChange={handleNewCompanyChange}
              placeholder="Enter company name"
              className="mt-1"
              required
            />
          </div>
          
          <div>
            <Label htmlFor="website">Website</Label>
            <Input
              id="website"
              name="website"
              value={newCompany.website ?? ''}
              onChange={handleNewCompanyChange}
              placeholder="https://example.com"
              className="mt-1"
            />
          </div>
          
          <div>
            <Label htmlFor="location">Location</Label>
            <Input
              id="location"
              name="location"
              value={newCompany.location ?? ''}
              onChange={handleNewCompanyChange}
              placeholder="City, State or Country"
              className="mt-1"
            />
          </div>
          
          <div>
            <Label htmlFor="industry">Industry</Label>
            <Input
              id="industry"
              name="industry"
              value={newCompany.industry ?? ''}
              onChange={handleNewCompanyChange}
              placeholder="e.g. Technology, Healthcare, etc."
              className="mt-1"
            />
          </div>
          
          <Button 
            className="w-full mt-2" 
            onClick={handleSaveNewCompany}
            disabled={isSaving}
          >
            {isSaving ? "Saving..." : "Save Company"}
          </Button>
        </div>
      </div>
    );
  }

  const showCommandList = searchQuery.trim().length > 0 || isSearching;
  const hasResults = localCompanies.length > 0 || pdlCompanies.length > 0;

  if (selectedCompany) {
    return (
      <div className="w-full">
        <SelectedCompany 
          company={selectedCompany} 
          onChangeClick={handleClearSelection} 
        />
      </div>
    );
  }

  return (
    <div className="w-full space-y-2">
      <Command className="rounded-lg border shadow-md">
        <CommandInput
          placeholder="Search for a company..."
          value={searchQuery}
          onValueChange={handleSearch}
        />
        {showCommandList && (
          <CommandList>
            {isSearching && (
              <div className="flex justify-center p-4">
                <Loader2 className="h-6 w-6 animate-spin text-muted-foreground" />
              </div>
            )}
            
            <CommandEmpty>
              {searchQuery.length > 0 ? (
                <div className="p-2 text-center">
                  <p>No companies found</p>
                  <Button 
                    variant="link" 
                    className="mt-1" 
                    onClick={handleCreateNewCompany}
                  >
                    <PlusCircle className="h-4 w-4 mr-2" />
                    Would you like to add one?
                  </Button>
                </div>
              ) : null}
            </CommandEmpty>

            {hasResults && (
              <>
                {localCompanies.map((company) => (
                  <CompanyItem 
                    key={company.id} 
                    company={company} 
                    onSelect={handleCompanySelect} 
                  />
                ))}
                
                {pdlCompanies.map((company) => (
                  <CompanyItem 
                    key={company.id} 
                    company={company} 
                    onSelect={handleCompanySelect} 
                  />
                ))}
              </>
            )}
          </CommandList>
        )}
      </Command>
    </div>
  );
};

export default CompanySearch;
