
import { createContext, useContext, useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Session, User } from "@supabase/supabase-js";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast";
import { logSupabaseChain, createSafeLogger } from "@/utils/debug-utils";

// Debugging: Check if Supabase is properly imported
console.log("Supabase in AuthContext:", supabase);
console.log("Supabase Auth in AuthContext:", supabase?.auth);
console.log("Supabase getSession:", supabase?.auth?.getSession);

// Safely debug the Supabase auth object chain
logSupabaseChain('supabase.auth', supabase?.auth);

interface AuthContextType {
  session: Session | null;
  user: User | null;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, firstName: string, lastName: string) => Promise<void>;
  signOut: () => Promise<void>;
}

// Export the context directly so it can be imported by test utils
export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    console.log("AuthProvider mounted, checking session...");

    // Debugging: Check if Supabase auth object exists
    if (!supabase.auth) {
      console.error("❌ Supabase auth is undefined! Check mock setup.");
    }

    // Safe logging wrapper for getSession
    const getSessionSafe = createSafeLogger(
      'supabase.auth.getSession', 
      () => supabase.auth.getSession()
    );

    // Get initial session
    getSessionSafe().then(({ data: { session } }) => {
      console.log("Initial session check:", session ? "Session exists" : "No session");
      setSession(session);
      setUser(session?.user ?? null);
      setLoading(false);
    }).catch((error) => {
      console.error("❌ Error fetching session:", error);
      setLoading(false); // Make sure to set loading to false even on error
    });

    // Log the auth state change setup
    logSupabaseChain('supabase.auth (before onAuthStateChange)', supabase.auth);

    // Listen for auth state changes
    const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
      console.log("Auth state changed:", _event, session);
      setSession(session);
      setUser(session?.user ?? null);
    });

    return () => {
      console.log("AuthProvider unmounted, cleaning up...");
      authListener?.subscription?.unsubscribe();
    };
  }, []);

  const signIn = async (email: string, password: string): Promise<void> => {
    console.log("Signing in user:", email);
    
    // Debug the signInWithPassword function
    logSupabaseChain('supabase.auth.signInWithPassword', supabase.auth.signInWithPassword);
    
    if (!supabase.auth.signInWithPassword) {
      console.error("❌ signInWithPassword method is not available on supabase.auth!");
      throw new Error("Authentication method not available");
    }
    
    try {
      const { data, error } = await supabase.auth.signInWithPassword({ 
        email, 
        password 
      });
  
      if (error) {
        console.error("❌ Sign-in error:", error);
        throw error;
      }
    } catch (error: any) {
      console.error("❌ Exception during sign-in:", error);
      throw error;
    }
  };

  const signUp = async (email: string, password: string, firstName: string, lastName: string): Promise<void> => {
    console.log("Signing up user:", email);
    
    if (!supabase.auth.signUp) {
      console.error("❌ signUp method is not available on supabase.auth!");
      throw new Error("Authentication method not available");
    }
    
    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            first_name: firstName,
            last_name: lastName,
          },
        },
      });
  
      if (error) {
        console.error("❌ Sign-up error:", error);
        throw error;
      }
    } catch (error: any) {
      console.error("❌ Exception during sign-up:", error);
      throw error;
    }
  };

  const signOut = async (): Promise<void> => {
    console.log("Signing out user...");
    
    if (!supabase.auth.signOut) {
      console.error("❌ signOut method is not available on supabase.auth!");
      throw new Error("Authentication method not available");
    }
    
    try {
      // First check if we have a session before attempting to sign out
      const { data } = await supabase.auth.getSession();
      console.log("Current session before sign out:", data.session);
      
      const { error } = await supabase.auth.signOut();
      
      if (error) {
        console.error("❌ Sign-out error:", error);
        toast({
          title: "Sign out failed",
          description: error.message,
          variant: "destructive"
        });
        throw error;
      }
      
      // Force clear session and user state
      setSession(null);
      setUser(null);
      
      // Redirect to auth page after successful sign out
      navigate('/auth');
      console.log("Signed out successfully and redirected to /auth");
    } catch (error: any) {
      console.error("Error during sign out process:", error);
      
      // Even if there's an error, we should still reset the local state and redirect
      setSession(null);
      setUser(null);
      navigate('/auth');
      
      // Re-throw the error for the component to handle if needed
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ session, user, loading, signIn, signUp, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
