
import * as React from "react"
import { type ToastProps as UIToastProps } from "@/components/ui/toast"

// Type definitions
export type ToastActionElement = React.ReactNode

// Fixed: Changed interface extension to avoid incompatible types
export interface ToastProps extends Omit<UIToastProps, "title"> {
  id: string
  title?: React.ReactNode
  description?: React.ReactNode
  action?: ToastActionElement
  // Ensure we're using only the variants allowed by the UI component
  variant?: "default" | "destructive"
}

// Define ToasterToast as an alias for ToastProps to maintain backward compatibility
export type ToasterToast = ToastProps

export type Toast = Omit<ToastProps, "id">

export const actionTypes = {
  ADD_TOAST: "ADD_TOAST",
  UPDATE_TOAST: "UPDATE_TOAST",
  DISMISS_TOAST: "DISMISS_TOAST",
  REMOVE_TOAST: "REMOVE_TOAST",
} as const

export type ActionType = typeof actionTypes

export type Action =
  | {
      type: ActionType["ADD_TOAST"]
      toast: ToastProps
    }
  | {
      type: ActionType["UPDATE_TOAST"]
      toast: Partial<ToastProps>
    }
  | {
      type: ActionType["DISMISS_TOAST"]
      toastId?: string
    }
  | {
      type: ActionType["REMOVE_TOAST"]
      toastId?: string
    }

export interface State {
  toasts: ToastProps[]
}
