
import { createClient } from '@supabase/supabase-js'
import type { Database } from './types';

// Define basic environment values directly
// This avoids circular dependencies when importing from environments/
const environmentValues = {
  development: {
    supabaseUrl: 'https://degmiwtlrruthkczyrqe.supabase.co',
    supabaseAnonKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRlZ21pd3RscnJ1dGhrY3p5cnFlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDExNDAzMjksImV4cCI6MjA1NjcxNjMyOX0.70rRv_vjRrs2SFRM2mEiovExPu9sSZ1Kup4GgupKLkg',
    production: false
  },
  qa: {
    supabaseUrl: 'https://degmiwtlrruthkczyrqe.supabase.co',
    supabaseAnonKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRlZ21pd3RscnJ1dGhrY3p5cnFlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDExNDAzMjksImV4cCI6MjA1NjcxNjMyOX0.70rRv_vjRrs2SFRM2mEiovExPu9sSZ1Kup4GgupKLkg',
    production: false
  },
  production: {
    supabaseUrl: 'https://httuddfmxzblyssvkysh.supabase.co',
    supabaseAnonKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imh0dHVkZGZteHpibHlzc3ZreXNoIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDA4NTEwNTksImV4cCI6MjA1NjQyNzA1OX0.Bz-40X3U0YEClEUGWm3iiIYg_HUCipReHAVLNVKDAJo',
    production: true
  }
};

// Function to determine which environment to use
const getEnvironment = () => {
  // ENHANCED DEBUGGING: Log important details
  console.log('[SUPABASE ENV] Starting environment selection...');
  console.log('[SUPABASE ENV] Browser hostname:', window.location.hostname);
  console.log('[SUPABASE ENV] Document last modified:', document.lastModified);
  console.log('[SUPABASE ENV] NETLIFY_CONTEXT:', import.meta.env.NETLIFY_CONTEXT);
  console.log('[SUPABASE ENV] BRANCH:', import.meta.env.BRANCH);
  console.log('[SUPABASE ENV] VITE_ENVIRONMENT:', import.meta.env.VITE_ENVIRONMENT);
  console.log('[SUPABASE ENV] MODE:', import.meta.env.MODE);
  
  // Check for explicit Netlify environment variables (highest priority)
  const netlifyContext = import.meta.env.NETLIFY_CONTEXT || import.meta.env.CONTEXT;
  if (netlifyContext) {
    console.log('[SUPABASE ENV] Using Netlify context:', netlifyContext);
    if (netlifyContext === 'production') return environmentValues.production;
    if (netlifyContext === 'qa') return environmentValues.qa;
    if (netlifyContext === 'develop' || netlifyContext === 'deploy-preview' || netlifyContext === 'branch-deploy') {
      return environmentValues.development;
    }
  }
  
  // Check for the branch environment variable (high priority)
  const branch = import.meta.env.BRANCH;
  if (branch) {
    console.log('[SUPABASE ENV] Using branch:', branch);
    if (branch === 'main') return environmentValues.production;
    if (branch === 'qa') return environmentValues.qa;
    if (branch === 'develop') return environmentValues.development;
  }
  
  // Check URL patterns (medium priority)
  const hostname = window.location.hostname;
  
  // Production detection
  if (hostname === 'platform.ladderingup.com' || 
      hostname === 'ladderingup.com' || 
      hostname.includes('main--ladderingup') ||
      (hostname.includes('ladderingup.netlify.app') && !hostname.includes('dev-') && !hostname.includes('qa-'))) {
    console.log('[SUPABASE ENV] Detected production hostname:', hostname);  
    return environmentValues.production;
  }
  
  // QA detection
  if (hostname.includes('qa--ladderingup') || 
      hostname === 'qa.ladderingup.com' ||
      hostname.includes('qa-ladderingup.netlify.app')) {
    console.log('[SUPABASE ENV] Detected QA hostname:', hostname);
    return environmentValues.qa;
  }
  
  // Development detection - be more explicit with patterns
  if (hostname.includes('dev-') || 
      hostname.includes('dev.') || 
      hostname.includes('develop--') || 
      hostname.includes('localhost') ||
      hostname.includes('127.0.0.1') ||
      hostname.includes('preview--') ||
      hostname.includes('deploy-preview-') ||
      hostname.includes('dev-ladderingup.netlify.app')) {
    console.log('[SUPABASE ENV] Detected development hostname:', hostname);
    return environmentValues.development;
  }
  
  // Check Vite environment settings (low priority)
  const viteEnv = import.meta.env.VITE_ENVIRONMENT;
  if (viteEnv) {
    console.log('[SUPABASE ENV] Using VITE_ENVIRONMENT:', viteEnv);
    if (viteEnv === 'production') return environmentValues.production;
    if (viteEnv === 'qa') return environmentValues.qa;
    if (viteEnv === 'development') return environmentValues.development;
  }
  
  console.log('[SUPABASE ENV] Defaulting to development environment');
  // Default to development
  return environmentValues.development;
};

// Get the appropriate environment configuration
const selectedEnv = getEnvironment();
console.log('[SUPABASE ENV] Selected environment:', selectedEnv.production ? 'production' : 'development/qa');

// Create a single supabase client for interacting with your database
export const supabase = createClient<Database>(
  selectedEnv.supabaseUrl,
  selectedEnv.supabaseAnonKey
);

// Debug log to inspect Supabase client structure AFTER it's created
console.log('[SUPABASE ENV] Client created for URL:', selectedEnv.supabaseUrl);

// Enhanced debugging to specifically show auth methods
console.log('[SUPABASE ENV] Auth methods available:', {
  // Log out the available methods on supabase.auth
  hasGetSession: typeof supabase.auth.getSession === 'function',
  hasSignInWithPassword: typeof supabase.auth.signInWithPassword === 'function',
  hasSignUp: typeof supabase.auth.signUp === 'function',
  hasSignOut: typeof supabase.auth.signOut === 'function',
  hasOnAuthStateChange: typeof supabase.auth.onAuthStateChange === 'function',
});

// Improved helper to check the current environment
export const isProduction = () => {
  // Check for explicit Netlify context first
  if (import.meta.env.NETLIFY_CONTEXT === 'production' || import.meta.env.CONTEXT === 'production') {
    return true;
  }
  
  // Check for explicit branch
  if (import.meta.env.BRANCH === 'main') {
    return true;
  }
  
  // Check hostname patterns
  const hostname = window.location.hostname;
  return (
    hostname === 'platform.ladderingup.com' || 
    hostname === 'ladderingup.com' || 
    hostname.includes('main--ladderingup') || 
    (hostname.includes('ladderingup.netlify.app') && !hostname.includes('dev-') && !hostname.includes('qa-')) ||
    (selectedEnv.production && import.meta.env.MODE === 'production')
  );
};

// Improved helper to check if we're in QA environment
export const isQA = () => {
  const hostname = window.location.hostname;
  return (
    hostname.includes('qa--ladderingup') || 
    hostname === 'qa.ladderingup.com' ||
    hostname.includes('qa-ladderingup.netlify.app') ||
    import.meta.env.MODE === 'qa'
  );
};

// Helper to check if we're in development environment
export const isDevelopment = () => {
  const hostname = window.location.hostname;
  return (
    hostname.includes('develop--ladderingup') ||
    hostname.includes('dev--ladderingup') ||
    hostname.includes('ladderingup-web-app--develop') ||
    hostname.includes('dev-ladderingup.netlify.app') ||
    hostname.includes('localhost') ||
    hostname.includes('127.0.0.1') ||
    hostname.includes('preview--') ||
    hostname.includes('deploy-preview') ||
    (!isProduction() && !isQA())
  );
};

// Helper to check if authentication is required in current environment
export const isAuthRequired = () => {
  // Always require auth in production (regardless of env setting)
  if (isProduction()) {
    return true;
  }
  
  // For QA and development, assume false since we can't access the full environment
  return false;
};

// Get build information for footer and debugging
export const getBuildInfo = () => {
  // Import environment only when needed to avoid circular dependencies
  let environmentName = 'development';
  
  if (isProduction()) {
    environmentName = 'production';
  } else if (isQA()) {
    environmentName = 'qa';
  }
  
  // Import the environment settings
  let version = '0.0.0';
  try {
    // Try to import the environment settings at runtime to get the version
    import('@/environments/index').then(module => {
      version = module.environment.version;
    }).catch(() => {
      // If import fails, use a default version
      console.warn('Failed to import environment settings');
    });
  } catch (e) {
    console.warn('Failed to import environment settings', e);
  }
  
  return {
    version: version,
    buildDate: new Date().toISOString(),
    environment: environmentName,
    supabaseUrl: selectedEnv.supabaseUrl,
  };
};

// Add a helper to get the API base URL
export const getApiBaseUrl = () => {
  return `${selectedEnv.supabaseUrl}/functions/v1`;
};
