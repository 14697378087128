
import React, { useState, useEffect } from 'react';
import { useJobContext } from '@/context/JobContext';
import JobCard from '@/components/JobCard';
import JobDetailModal from '@/components/JobDetailModal';
import CreateJobModal from '@/components/CreateJobModal';
import { Button } from '@/components/ui/button';
import { JobStatus } from '@/types';
import { Plus, Loader2 } from 'lucide-react';
import { useAuth } from '@/context/AuthContext';
import { useNavigate } from 'react-router-dom';
import EnvironmentIndicator from '@/components/EnvironmentIndicator';

const KanbanBoard = () => {
  const { jobs, selectedJob, setSelectedJob, updateJobStatus, loading, refreshData } = useJobContext();
  const [draggingJobId, setDraggingJobId] = useState<string | null>(null);
  const [createJobModalOpen, setCreateJobModalOpen] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  // Check if user is authenticated
  useEffect(() => {
    if (!user) {
      navigate('/auth');
    }
  }, [user, navigate]);

  // Refresh data when component mounts
  useEffect(() => {
    if (user) {
      refreshData();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  // Column definitions
  const columns: { id: JobStatus; title: string }[] = [
    { id: 'new', title: 'New' },
    { id: 'applied', title: 'Applied' },
    { id: 'interviewing', title: 'Interviewing' },
    { id: 'negotiating', title: 'Negotiating' },
    { id: 'offer', title: 'Offer' },
    { id: 'declined', title: 'Declined' }
  ];

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, jobId: string) => {
    setDraggingJobId(jobId);
    e.dataTransfer.setData('text/plain', jobId);
    
    // Create a custom drag image (optional)
    const dragPreview = document.createElement('div');
    dragPreview.className = 'bg-white p-2 rounded shadow-md border border-primary/30 text-sm font-medium';
    const job = jobs.find(j => j.id === jobId);
    dragPreview.textContent = job?.title || 'Job';
    document.body.appendChild(dragPreview);
    e.dataTransfer.setDragImage(dragPreview, 20, 20);
    setTimeout(() => {
      document.body.removeChild(dragPreview);
    }, 0);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>, status: JobStatus) => {
    e.preventDefault();
    const jobId = e.dataTransfer.getData('text/plain');
    if (jobId && draggingJobId) {
      await updateJobStatus(jobId, status);
      setDraggingJobId(null);
    }
  };

  const getJobsByStatus = (status: JobStatus) => {
    return jobs.filter(job => job.status === status);
  };

  if (!user) {
    return null; // Will redirect in useEffect
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-200px)]">
        <div className="text-center">
          <Loader2 className="h-10 w-10 animate-spin mx-auto text-primary" />
          <p className="mt-4 text-muted-foreground">Loading your job board...</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="mb-6">
        <div className="flex items-center">
          <h1 className="text-3xl font-bold tracking-tight">Kanban Board</h1>
          <EnvironmentIndicator />
        </div>
        <p className="text-muted-foreground mt-1">
          Drag and drop jobs between stages to update their status
        </p>
      </div>

      <div className="flex justify-end mb-4">
        <Button onClick={() => setCreateJobModalOpen(true)}>
          <Plus size={16} className="mr-2" />
          Add New Job
        </Button>
      </div>

      <div className="flex overflow-x-auto pb-4 scrollbar-hidden gap-4">
        {columns.map(column => (
          <div 
            key={column.id}
            className="flex-shrink-0 w-72 bg-muted/30 rounded-lg border border-border/60 overflow-hidden flex flex-col"
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, column.id)}
          >
            <div className="p-3 border-b border-border/60 bg-white">
              <div className="flex items-center justify-between">
                <h3 className="font-medium">{column.title}</h3>
                <span className="text-xs font-medium bg-muted px-2 py-1 rounded-full">
                  {getJobsByStatus(column.id).length}
                </span>
              </div>
            </div>
            
            <div className="p-2 flex-1 min-h-[calc(100vh-280px)] overflow-y-auto scrollbar-hidden">
              {getJobsByStatus(column.id).length > 0 ? (
                <div className="space-y-3">
                  {getJobsByStatus(column.id).map(job => (
                    <div
                      key={job.id}
                      draggable
                      onDragStart={(e) => handleDragStart(e, job.id)}
                      className={`transition-opacity ${draggingJobId === job.id ? 'opacity-50' : 'opacity-100'}`}
                    >
                      <JobCard job={job} onClick={setSelectedJob} />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="h-24 flex items-center justify-center border-2 border-dashed border-border/40 rounded-md">
                  <p className="text-sm text-muted-foreground">Drop jobs here</p>
                </div>
              )}
            </div>
            
            <div className="p-2 border-t border-border/60 bg-white">
              <Button 
                variant="ghost" 
                size="sm" 
                className="w-full justify-start text-muted-foreground"
                onClick={() => setCreateJobModalOpen(true)}
              >
                <Plus size={16} />
                Add Job
              </Button>
            </div>
          </div>
        ))}
      </div>

      {/* Job Detail Modal */}
      <JobDetailModal 
        job={selectedJob} 
        open={!!selectedJob} 
        onOpenChange={(open) => !open && setSelectedJob(null)} 
      />

      {/* Create Job Modal */}
      <CreateJobModal
        open={createJobModalOpen}
        onOpenChange={setCreateJobModalOpen}
      />
    </div>
  );
};

export default KanbanBoard;
