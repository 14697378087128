
import React from 'react';
import { Button } from "@/components/ui/button";
import { jsPDF } from 'jspdf';
import { Download } from 'lucide-react';
import { getApiBaseUrl } from '@/integrations/supabase/client';

const ApiDocsDownload: React.FC = () => {
  const generateApiDocsPDF = (): void => {
    const apiBaseUrl = getApiBaseUrl();
    
    // Create a new jsPDF instance
    const doc = new jsPDF();
    
    // Set title
    doc.setFontSize(22);
    doc.text('API Documentation for LadderingUp Extensions', 20, 20);
    
    // Set font size for content
    doc.setFontSize(12);
    
    let yPosition = 30;
    const lineHeight = 7;
    const margin = 20;
    const pageWidth = doc.internal.pageSize.width;
    const maxWidth = pageWidth - margin * 2;
    
    // Add introduction
    doc.text('This document outlines the API endpoints available for Chrome extension and Gmail add-on integrations.', margin, yPosition);
    yPosition += lineHeight * 2;
    
    // Base URL
    doc.setFontSize(16);
    doc.text('Base URL', margin, yPosition);
    yPosition += lineHeight;
    doc.setFontSize(12);
    doc.text('All API calls should be made to:', margin, yPosition);
    yPosition += lineHeight;
    doc.setFont(undefined, 'bold');
    doc.text(apiBaseUrl, margin, yPosition);
    doc.setFont(undefined, 'normal');
    yPosition += lineHeight * 2;
    
    // Authentication
    doc.setFontSize(16);
    doc.text('Authentication', margin, yPosition);
    yPosition += lineHeight;
    doc.setFontSize(12);
    doc.text('Most endpoints require a user ID for authentication included in the request body as "userId".', margin, yPosition);
    yPosition += lineHeight * 2;
    
    // Add Endpoints
    doc.setFontSize(18);
    doc.text('Endpoints', margin, yPosition);
    yPosition += lineHeight * 1.5;
    
    // 1. Import Job
    doc.setFontSize(16);
    doc.text('1. Import Job from External Source', margin, yPosition);
    yPosition += lineHeight;
    doc.setFontSize(12);
    doc.text('Allows job information to be scraped from websites and imported.', margin, yPosition);
    yPosition += lineHeight;
    doc.text('Endpoint: /external_integrations/scrapejob', margin, yPosition);
    yPosition += lineHeight;
    doc.text('Method: POST', margin, yPosition);
    yPosition += lineHeight * 1.5;
    
    // Request sample
    doc.setFontSize(14);
    doc.text('Request Example:', margin, yPosition);
    yPosition += lineHeight;
    doc.setFontSize(10);
    const jobRequestExample = 
`{
  "userId": "user-uuid-here",
  "jobData": {
    "title": "Job Title",
    "company": "Company Name",
    "location": "Job Location",
    "description": "Job Description",
    "url": "https://job-listing-url.com",
    "source": "chrome_extension"
  }
}`;
    
    const splitJobRequest = doc.splitTextToSize(jobRequestExample, maxWidth);
    doc.text(splitJobRequest, margin, yPosition);
    yPosition += splitJobRequest.length * 5;
    
    // Add a new page if we're getting close to the bottom
    if (yPosition > 250) {
      doc.addPage();
      yPosition = 20;
    }
    
    // Response sample
    doc.setFontSize(14);
    doc.text('Response Example:', margin, yPosition);
    yPosition += lineHeight;
    doc.setFontSize(10);
    const jobResponseExample = 
`{
  "success": true,
  "message": "Job successfully imported",
  "jobId": "generated-job-uuid"
}`;
    const splitJobResponse = doc.splitTextToSize(jobResponseExample, maxWidth);
    doc.text(splitJobResponse, margin, yPosition);
    yPosition += splitJobResponse.length * 5 + lineHeight;
    
    // 2. Add Email Note to Job
    doc.setFontSize(16);
    doc.text('2. Add Email Note to Job', margin, yPosition);
    yPosition += lineHeight;
    doc.setFontSize(12);
    doc.text('Allows email correspondence to be imported as a note.', margin, yPosition);
    yPosition += lineHeight;
    doc.text('Endpoint: /external_integrations/emailnote', margin, yPosition);
    yPosition += lineHeight;
    doc.text('Method: POST', margin, yPosition);
    yPosition += lineHeight * 1.5;
    
    // New page for email example
    doc.addPage();
    yPosition = 20;
    
    // Email request sample
    doc.setFontSize(14);
    doc.text('Request Example (attach to existing job):', margin, yPosition);
    yPosition += lineHeight;
    doc.setFontSize(10);
    const emailRequestExample = 
`{
  "userId": "user-uuid-here",
  "emailData": {
    "jobId": "existing-job-uuid",
    "emailSubject": "Interview Scheduled",
    "emailBody": "Content of the email...",
    "emailDate": "2023-06-16T10:30:00Z",
    "emailSender": "recruiter@company.com",
    "source": "gmail_addon"
  }
}`;
    const splitEmailRequest = doc.splitTextToSize(emailRequestExample, maxWidth);
    doc.text(splitEmailRequest, margin, yPosition);
    yPosition += splitEmailRequest.length * 5 + lineHeight;
    
    // Email response sample
    doc.setFontSize(14);
    doc.text('Response Example:', margin, yPosition);
    yPosition += lineHeight;
    doc.setFontSize(10);
    const emailResponseExample = 
`{
  "success": true,
  "message": "Email note successfully added",
  "noteId": "generated-note-uuid",
  "jobId": "job-uuid"
}`;
    const splitEmailResponse = doc.splitTextToSize(emailResponseExample, maxWidth);
    doc.text(splitEmailResponse, margin, yPosition);
    yPosition += splitEmailResponse.length * 5 + lineHeight * 2;
    
    // 3. Company Lookup
    doc.setFontSize(16);
    doc.text('3. Company Lookup', margin, yPosition);
    yPosition += lineHeight;
    doc.setFontSize(12);
    doc.text('Search for companies in both the local database and PDL API.', margin, yPosition);
    yPosition += lineHeight;
    doc.text('Endpoint: /external_integrations/company_lookup', margin, yPosition);
    yPosition += lineHeight;
    doc.text('Method: POST', margin, yPosition);
    yPosition += lineHeight * 1.5;
    
    // New page for error handling and implementation
    doc.addPage();
    yPosition = 20;
    
    // Error Handling
    doc.setFontSize(16);
    doc.text('Error Handling', margin, yPosition);
    yPosition += lineHeight;
    doc.setFontSize(12);
    doc.text('All endpoints return appropriate HTTP status codes:', margin, yPosition);
    yPosition += lineHeight;
    doc.text('• 200: Success', margin, yPosition);
    yPosition += lineHeight;
    doc.text('• 400: Bad request (invalid parameters)', margin, yPosition);
    yPosition += lineHeight;
    doc.text('• 401: Unauthorized (invalid or missing user ID)', margin, yPosition);
    yPosition += lineHeight;
    doc.text('• 404: Endpoint not found', margin, yPosition);
    yPosition += lineHeight;
    doc.text('• 500: Server error', margin, yPosition);
    yPosition += lineHeight * 2;
    
    // Save the PDF with a download prompt
    doc.save('LadderingUp_API_Documentation.pdf');
  };

  return (
    <Button
      onClick={generateApiDocsPDF}
      className="flex items-center gap-2"
      variant="outline"
    >
      <Download size={16} />
      Download API Documentation
    </Button>
  );
};

export default ApiDocsDownload;
