
import React, { useState, useEffect } from 'react';
import { environment } from '@/environments/index';
import { Card } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AlertCircle, Check, Copy, Server, Info, FileText } from 'lucide-react';
import { getBuildInfo } from '@/integrations/supabase/client';

/**
 * EnvironmentDebugger - A component to inspect environment variables across deployments
 * This is useful for diagnosing deployment issues and verifying that the correct environment is used
 */
export const EnvironmentDebugger: React.FC = () => {
  const [copied, setCopied] = useState(false);
  const [deployInfo, setDeployInfo] = useState<Record<string, any> | null>(null);
  const [networkError, setNetworkError] = useState<string | null>(null);
  const [fileTimestamps, setFileTimestamps] = useState<Record<string, string>>({});
  const buildInfo = getBuildInfo();
  
  // Format data for display
  const currentEnvironment = {
    ...environment,
    // Replace sensitive values with placeholders
    supabaseAnonKey: environment.supabaseAnonKey ? '****' + environment.supabaseAnonKey.slice(-8) : 'not set',
    buildInfo: {
      ...buildInfo
    }
  };

  // Fetch Netlify deploy context information which is more reliable than version numbers
  useEffect(() => {
    const fetchDeployContext = async () => {
      try {
        // This API provides immutable build context data from Netlify
        const response = await fetch('/.netlify/functions/environment-info', { 
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        });
        
        if (!response.ok) {
          setNetworkError(`API returned ${response.status}: ${response.statusText}`);
          return;
        }
        
        const data = await response.json();
        setDeployInfo(data);
      } catch (error) {
        // This will fail in development but that's OK - we'll use a fallback
        console.log('Could not fetch deploy context, using fallback method');
        
        // Fallback: collect environment information directly from the browser
        setDeployInfo({
          netlifyInfo: null,
          deployTimestamp: new Date().toISOString(),
          buildId: 'local-development',
          commitRef: 'local',
          context: 'development',
          nodeVersion: import.meta.env.VITE_NODE_VERSION || 'unknown'
        });
      }
    };

    fetchDeployContext();
  }, []);

  // Get file modification timestamps
  useEffect(() => {
    // Get document last modified date
    const htmlModified = document.lastModified;
    
    // Get JS main bundle timestamp by making a HEAD request
    const checkFileTimestamp = async (filePath: string) => {
      try {
        const response = await fetch(filePath, { method: 'HEAD' });
        const lastModified = response.headers.get('last-modified');
        return lastModified || 'unknown';
      } catch (e) {
        console.error(`Failed to get timestamp for ${filePath}`, e);
        return 'error';
      }
    };
    
    const collectTimestamps = async () => {
      // Get timestamps for key files
      const mainBundle = await checkFileTimestamp('/assets/index.js');
      const cssBundle = await checkFileTimestamp('/assets/index.css');
      
      setFileTimestamps({
        htmlDocument: htmlModified,
        mainJsBundle: mainBundle,
        cssBundle: cssBundle
      });
    };
    
    collectTimestamps();
  }, []);

  // Detect environment based on hostname, URL, and environment variables
  const getEnvironmentInfo = () => {
    const hostname = window.location.hostname;
    const buildMode = import.meta.env.MODE;
    const isProd = environment.production === true;
    const buildTimestamp = import.meta.env.VITE_BUILD_TIMESTAMP || document.lastModified;
    
    // Get deploy ID from URL parameter if present (Netlify adds this for deploy previews)
    const urlParams = new URLSearchParams(window.location.search);
    const deployId = urlParams.get('nf_deploy_id') || null;
    
    return {
      hostname,
      detectedEnv: isProd ? 'production' : (environment.version?.includes('-qa') ? 'qa' : 'development'),
      buildMode: buildMode || 'unknown',
      version: environment.version || 'unknown',
      url: window.location.href,
      buildTimestamp,
      documentLastModified: document.lastModified,
      deployId
    };
  };
  
  const envInfo = getEnvironmentInfo();
  
  const copyToClipboard = () => {
    const combinedData = {
      environment: currentEnvironment,
      buildInfo,
      deployInfo,
      environmentInfo: envInfo,
      fileTimestamps
    };
    
    const envData = JSON.stringify(combinedData, null, 2);
    navigator.clipboard.writeText(envData);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  // Calculate a fingerprint for the environment that should be consistent
  // between identical deployments but different across environments
  const calculateFingerprint = () => {
    if (!deployInfo) return 'calculating...';
    
    // Combine multiple factors that won't change between browser sessions
    // but will be different across environments
    const fingerprintSource = [
      environment.supabaseUrl,
      environment.apiUrl,
      deployInfo.buildId || '',
      deployInfo.commitRef || '',
      environment.buildDate,
      document.lastModified // Add document modification date for more reliable comparison
    ].join('|');
    
    // Create a simple hash (this is not cryptographically secure, just for comparison)
    let hash = 0;
    for (let i = 0; i < fingerprintSource.length; i++) {
      const char = fingerprintSource.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    
    // Convert to a readable hex string
    return (hash >>> 0).toString(16).padStart(8, '0');
  };

  return (
    <Card className="p-4 md:p-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold flex items-center">
          <Server className="mr-2 h-5 w-5" />
          Environment Debugger
        </h2>
        <Button 
          variant="outline" 
          size="sm" 
          onClick={copyToClipboard}
          className="flex items-center"
        >
          {copied ? (
            <>
              <Check className="h-4 w-4 mr-1" />
              Copied
            </>
          ) : (
            <>
              <Copy className="h-4 w-4 mr-1" />
              Copy Config
            </>
          )}
        </Button>
      </div>
      
      <Alert className="mb-4">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Environment Detection</AlertTitle>
        <AlertDescription>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-2">
            <div>
              <strong>Hostname:</strong> {envInfo.hostname}
            </div>
            <div>
              <strong>Detected Environment:</strong> {envInfo.detectedEnv}
            </div>
            <div>
              <strong>Build Mode:</strong> {envInfo.buildMode}
            </div>
            <div>
              <strong>Version:</strong> {envInfo.version}
            </div>
          </div>

          <div className="mt-4 bg-muted p-2 rounded-md">
            <div className="flex items-center text-sm mb-2">
              <FileText className="h-4 w-4 mr-1" />
              <span className="font-bold">HTML Document Last Modified:</span> 
              <code className="ml-2 bg-secondary p-1 rounded text-xs">{document.lastModified}</code>
            </div>
            <div className="flex items-center text-sm">
              <Info className="h-4 w-4 mr-1" />
              <span className="font-bold">Environment Fingerprint:</span> 
              <code className="ml-2 bg-secondary p-1 rounded text-xs">{calculateFingerprint()}</code>
              <span className="ml-2 text-xs text-muted-foreground">(Use this to compare environments)</span>
            </div>
          </div>
        </AlertDescription>
      </Alert>

      <Tabs defaultValue="file-timestamps">
        <TabsList>
          <TabsTrigger value="file-timestamps">File Timestamps</TabsTrigger>
          <TabsTrigger value="config">Configuration</TabsTrigger>
          <TabsTrigger value="endpoints">API Endpoints</TabsTrigger>
          <TabsTrigger value="build">Build Info</TabsTrigger>
          <TabsTrigger value="deploy">Deploy Context</TabsTrigger>
        </TabsList>
        
        <TabsContent value="file-timestamps" className="mt-4">
          <div className="bg-muted p-4 rounded-md">
            <h3 className="text-sm font-medium mb-2">File Modification Timestamps</h3>
            {Object.keys(fileTimestamps).length > 0 ? (
              <table className="w-full text-xs">
                <thead>
                  <tr className="border-b">
                    <th className="text-left py-2">File</th>
                    <th className="text-left py-2">Last Modified</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b">
                    <td className="py-2">HTML Document (index.html)</td>
                    <td className="py-2">{fileTimestamps.htmlDocument || document.lastModified}</td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2">Main JS Bundle</td>
                    <td className="py-2">{fileTimestamps.mainJsBundle || 'Checking...'}</td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2">CSS Bundle</td>
                    <td className="py-2">{fileTimestamps.cssBundle || 'Checking...'}</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div className="text-center py-4 text-muted-foreground">Loading file timestamps...</div>
            )}
          </div>
        </TabsContent>
        
        <TabsContent value="config" className="mt-4">
          <div className="bg-muted p-4 rounded-md">
            <pre className="text-xs overflow-auto max-h-[400px]">
              {JSON.stringify({
                version: environment.version,
                production: environment.production,
                requireAuth: environment.requireAuth,
                buildDate: environment.buildDate,
              }, null, 2)}
            </pre>
          </div>
        </TabsContent>
        
        <TabsContent value="endpoints" className="mt-4">
          <div className="bg-muted p-4 rounded-md">
            <pre className="text-xs overflow-auto max-h-[400px]">
              {JSON.stringify({
                apiUrl: environment.apiUrl,
                supabaseUrl: environment.supabaseUrl,
                // Add truncated key for security
                supabaseAnonKey: environment.supabaseAnonKey ? 
                  `****${environment.supabaseAnonKey.slice(-8)}` : 'not set',
              }, null, 2)}
            </pre>
          </div>
        </TabsContent>
        
        <TabsContent value="build" className="mt-4">
          <div className="bg-muted p-4 rounded-md">
            <pre className="text-xs overflow-auto max-h-[400px]">
              {JSON.stringify(buildInfo, null, 2)}
            </pre>
          </div>
        </TabsContent>
        
        <TabsContent value="deploy" className="mt-4">
          <div className="bg-muted p-4 rounded-md">
            {deployInfo ? (
              <pre className="text-xs overflow-auto max-h-[400px]">
                {JSON.stringify(deployInfo, null, 2)}
              </pre>
            ) : networkError ? (
              <div className="text-red-500 text-sm">
                Error fetching deploy info: {networkError}
              </div>
            ) : (
              <div className="flex justify-center items-center h-20 text-muted-foreground">
                Loading deploy context...
              </div>
            )}
          </div>
        </TabsContent>
      </Tabs>
      
      <div className="mt-4 text-sm text-muted-foreground">
        <p>
          For troubleshooting deployment issues, compare the <strong>File Timestamps</strong> tab and 
          <strong>HTML Document Last Modified</strong> date across environments.
          These values provide an immutable way to identify and compare file deployments.
        </p>
      </div>
    </Card>
  );
};

export default EnvironmentDebugger;
