
import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { Job, Task, JobStatus, JobActivity, NoteType, JobNoteDB } from "@/types";
import { useJobContext } from "@/context/JobContext";
import { Briefcase, User, MapPin, Calendar, DollarSign, Link, Mail, Phone, FileText, Loader2, Save, X, Eye, Clock, Activity, MessageSquare, Linkedin, PhoneCall } from "lucide-react";
import TaskItem from "./TaskItem";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { toast } from "@/hooks/use-toast";
import { format } from "date-fns";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/context/AuthContext";

interface JobDetailModalProps {
  job: Job | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

// Define types for notes and grouped notes
interface JobNoteWithId {
  id: string;
  job_id: string;
  user_id: string;
  type: NoteType;
  content: string;
  timestamp: string;
}

interface GroupedNotes {
  date: string;
  formattedDate: string;
  notes: JobNoteWithId[];
}

interface GroupedActivities {
  date: string;
  formattedDate: string;
  activities: JobActivity[];
}

const JobDetailModal: React.FC<JobDetailModalProps> = ({ job, open, onOpenChange }) => {
  const { getJobTasks, addTask, updateTask, toggleTaskCompletion, deleteTask, deleteJob, updateJob, getJobActivities } = useJobContext();
  const [activeTab, setActiveTab] = useState("details");
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedJob, setEditedJob] = useState<Job | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [notes, setNotes] = useState<JobNoteWithId[]>([]);
  const [newNote, setNewNote] = useState({ type: 'general' as NoteType, content: '' });
  const [isAddingNote, setIsAddingNote] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if (job) {
      setEditedJob(job);
      setIsEditing(false);
      loadNotes(job.id);
    }
  }, [job]);

  const loadNotes = async (jobId: string) => {
    try {
      const { data, error } = await supabase
        .from('job_notes')
        .select('*')
        .eq('job_id', jobId)
        .order('timestamp', { ascending: false });
      
      if (error) {
        console.error("Error loading notes:", error);
        return;
      }
      
      const typedNotes: JobNoteWithId[] = (data || []).map((note: JobNoteDB) => ({
        ...note,
        type: note.type as NoteType
      }));
      
      setNotes(typedNotes);
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };

  const addNote = async () => {
    if (!job || !user || !newNote.content.trim()) return;
    
    setIsAddingNote(true);
    
    try {
      const noteData = {
        job_id: job.id,
        user_id: user.id,
        type: newNote.type,
        content: newNote.content,
        timestamp: new Date().toISOString(),
      };
      
      const { data, error } = await supabase
        .from('job_notes')
        .insert(noteData)
        .select();
      
      if (error) throw error;
      
      const typedNewNotes: JobNoteWithId[] = (data || []).map((note: JobNoteDB) => ({
        ...note,
        type: note.type as NoteType
      }));
      
      setNotes([...typedNewNotes, ...notes]);
      setNewNote({ type: 'general', content: '' });
      
      const { error: activityError } = await supabase
        .from('job_activities')
        .insert({
          job_id: job.id,
          user_id: user.id,
          type: 'note_added',
          message: `Added a new ${newNote.type} note`,
          timestamp: new Date().toISOString(),
        });
      
      if (activityError) {
        console.error("Error adding activity:", activityError);
      }
      
      toast({
        title: "Note Added",
        description: "Your note has been added to this job",
      });
    } catch (error: unknown) {
      console.error("Error adding note:", error);
      toast({
        title: "Error adding note",
        description: error instanceof Error ? error.message : "An unknown error occurred",
        variant: "destructive",
      });
    } finally {
      setIsAddingNote(false);
    }
  };

  const getNoteIcon = (type: NoteType) => {
    switch (type) {
      case 'email':
        return <Mail className="h-4 w-4 text-blue-500" />;
      case 'phone':
        return <PhoneCall className="h-4 w-4 text-green-500" />;
      case 'linkedin':
        return <Linkedin className="h-4 w-4 text-blue-700" />;
      case 'interview':
        return <User className="h-4 w-4 text-purple-500" />;
      case 'other':
        return <Clock className="h-4 w-4 text-orange-500" />;
      case 'general':
      default:
        return <MessageSquare className="h-4 w-4 text-gray-500" />;
    }
  };

  if (!job) return null;

  const jobTasks = getJobTasks(job.id);
  const jobActivities = getJobActivities(job.id);
  
  const formatDate = (dateString?: string) => {
    if (!dateString) return "N/A";
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const formatTime = (dateString: string) => {
    return format(new Date(dateString), 'hh:mm a');
  };

  interface GroupedActivity {
    date: string;
    formattedDate: string;
    activities: JobActivity[];
  }

  const groupActivitiesByDate = (activities: JobActivity[]): GroupedActivity[] => {
    const groups: Record<string, JobActivity[]> = {};
   
    activities.forEach(activity => {
      const date = new Date(activity.timestamp).toISOString().split('T')[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(activity);
    });
    
    return Object.entries(groups)
      .sort(([dateA], [dateB]) => new Date(dateB).getTime() - new Date(dateA).getTime())
      .map(([date, activities]) => ({
        date,
        formattedDate: formatDate(date) || '',
        activities: activities.sort((a, b) => 
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        )
      }));
  };

  const getActivityIcon = (type: JobActivity['type']) => {
    switch (type) {
      case 'status_change':
        return <Activity className="h-4 w-4 text-blue-500" />;
      case 'note_added':
      case 'note_updated':
        return <FileText className="h-4 w-4 text-yellow-500" />;
      case 'contact_added':
      case 'contact_updated':
        return <User className="h-4 w-4 text-green-500" />;
      case 'application_submitted':
        return <Briefcase className="h-4 w-4 text-purple-500" />;
      case 'job_created':
        return <Briefcase className="h-4 w-4 text-indigo-500" />;
      case 'job_updated':
        return <Save className="h-4 w-4 text-orange-500" />;
      default:
        return <Clock className="h-4 w-4 text-gray-500" />;
    }
  };

  interface GroupedNote {
    date: string;
    formattedDate: string;
    notes: JobNoteWithId[];
  }

  const groupNotesByDate = (): GroupedNote[] => {
    const groups: Record<string, JobNoteWithId[]> = {};    
    notes.forEach(note => {
      const date = new Date(note.timestamp).toISOString().split('T')[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(note);
    });
    
    return Object.entries(groups)
      .sort(([dateA], [dateB]) => new Date(dateB).getTime() - new Date(dateA).getTime())
      .map(([date, notes]) => ({
        date,
        formattedDate: formatDate(date) || '',
        notes: notes.sort((a, b) => 
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        )
      }));
  };

  const handleDeleteJob = async () => {
    if (window.confirm(`Are you sure you want to delete ${job.title} at ${job.company}?`)) {
      setIsDeleting(true);
      try {
        await deleteJob(job.id);
        onOpenChange(false);
      } catch (error) {
        console.error("Error deleting job:", error);
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    if (!isEditing) {
      setEditedJob({...job});
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!editedJob) return;
    
    setEditedJob({
      ...editedJob,
      [e.target.name]: e.target.value
    });
  };

  const handleSelectChange = (value: string, field: string) => {
    if (!editedJob) return;
    
    setEditedJob({
      ...editedJob,
      [field]: field === 'status' ? value as JobStatus : value
    });
  };

  const handleSaveEdit = async () => {
    if (!editedJob) return;
    
    setIsSaving(true);
    try {
      await updateJob(editedJob);
      setIsEditing(false);
      toast({
        title: "Job Updated",
        description: "The job details have been successfully updated."
      });
    } catch (error) {
      console.error("Error saving job:", error);
      toast({
        title: "Error Saving Job",
        description: "There was a problem updating the job details.",
        variant: "destructive"
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-2xl max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <div className="flex items-start space-x-4">
            {job.logo ? (
              <img
                src={job.logo}
                alt={`${job.company} logo`}
                className="w-16 h-16 rounded-md object-cover"
              />
            ) : (
              <div className="w-16 h-16 rounded-md bg-primary/10 flex items-center justify-center text-primary font-medium text-xl">
                {job.company.charAt(0)}
              </div>
            )}
            <div className="space-y-1">
              {isEditing ? (
                <div className="space-y-2">
                  <Label htmlFor="title">Position</Label>
                  <Input
                    id="title"
                    name="title"
                    value={editedJob?.title || ''}
                    onChange={handleInputChange}
                    placeholder="Position/Title"
                  />
                  <Label htmlFor="company">Company</Label>
                  <Input
                    id="company"
                    name="company"
                    value={editedJob?.company || ''}
                    onChange={handleInputChange}
                    placeholder="Company Name"
                  />
                </div>
              ) : (
                <>
                  <DialogTitle className="text-xl">{job.title}</DialogTitle>
                  <p className="text-muted-foreground">{job.company}</p>
                </>
              )}
              <div className="flex flex-wrap gap-2 mt-2">
                {isEditing ? (
                  <Select 
                    value={editedJob?.status || 'new'} 
                    onValueChange={(value) => handleSelectChange(value, 'status')}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Status" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="new">New</SelectItem>
                      <SelectItem value="applied">Applied</SelectItem>
                      <SelectItem value="interviewing">Interviewing</SelectItem>
                      <SelectItem value="negotiating">Negotiating</SelectItem>
                      <SelectItem value="offer">Offer</SelectItem>
                      <SelectItem value="declined">Declined</SelectItem>
                    </SelectContent>
                  </Select>
                ) : (
                  <span className={`text-xs px-2 py-1 rounded-full ${
                    job.status === 'new' ? 'bg-status-new text-blue-700' :
                    job.status === 'applied' ? 'bg-status-applied text-purple-700' :
                    job.status === 'interviewing' ? 'bg-status-interviewing text-cyan-700' :
                    job.status === 'negotiating' ? 'bg-status-negotiating text-yellow-700' :
                    job.status === 'offer' ? 'bg-status-offer text-green-700' :
                    'bg-status-declined text-red-700'
                  }`}>
                    {job.status.charAt(0).toUpperCase() + job.status.slice(1)}
                  </span>
                )}
              </div>
            </div>
          </div>
        </DialogHeader>

        <Tabs defaultValue="details" value={activeTab} onValueChange={setActiveTab} className="mt-4">
          <TabsList className="grid w-full grid-cols-5">
            <TabsTrigger value="details">Details</TabsTrigger>
            <TabsTrigger value="notes">Notes ({notes.length})</TabsTrigger>
            <TabsTrigger value="tasks">Tasks ({jobTasks.length})</TabsTrigger>
            <TabsTrigger value="description">Description</TabsTrigger>
            <TabsTrigger value="activity">Activity</TabsTrigger>
          </TabsList>

          <TabsContent value="details" className="pt-4 animate-fade-in">
            <div className="space-y-4">
              {isEditing ? (
                <div className="space-y-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <Label htmlFor="location">Location</Label>
                      <Input
                        id="location"
                        name="location"
                        value={editedJob?.location || ''}
                        onChange={handleInputChange}
                        placeholder="Location"
                      />
                    </div>
                    <div>
                      <Label htmlFor="salary">Salary</Label>
                      <Input
                        id="salary"
                        name="salary"
                        value={editedJob?.salary || ''}
                        onChange={handleInputChange}
                        placeholder="Salary"
                      />
                    </div>
                    <div>
                      <Label htmlFor="postedDate">Posted Date</Label>
                      <Input
                        id="postedDate"
                        name="postedDate"
                        type="date"
                        value={editedJob?.postedDate ? editedJob.postedDate.split('T')[0] : ''}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <Label htmlFor="appliedDate">Applied Date</Label>
                      <Input
                        id="appliedDate"
                        name="appliedDate"
                        type="date"
                        value={editedJob?.appliedDate ? editedJob.appliedDate.split('T')[0] : ''}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <Label htmlFor="url">Job URL</Label>
                      <Input
                        id="url"
                        name="url"
                        value={editedJob?.url || ''}
                        onChange={handleInputChange}
                        placeholder="https://example.com/job"
                      />
                    </div>
                    <div>
                      <Label htmlFor="contactName">Contact Name</Label>
                      <Input
                        id="contactName"
                        name="contactName"
                        value={editedJob?.contactName || ''}
                        onChange={handleInputChange}
                        placeholder="Contact Person"
                      />
                    </div>
                    <div>
                      <Label htmlFor="contactEmail">Contact Email</Label>
                      <Input
                        id="contactEmail"
                        name="contactEmail"
                        value={editedJob?.contactEmail || ''}
                        onChange={handleInputChange}
                        placeholder="contact@example.com"
                      />
                    </div>
                    <div>
                      <Label htmlFor="contactPhone">Contact Phone</Label>
                      <Input
                        id="contactPhone"
                        name="contactPhone"
                        value={editedJob?.contactPhone || ''}
                        onChange={handleInputChange}
                        placeholder="Contact Phone"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="space-y-3">
                      <div className="flex items-center space-x-2">
                        <MapPin size={16} className="text-muted-foreground" />
                        <span>{job.location}</span>
                      </div>
                      
                      {job.salary && (
                        <div className="flex items-center space-x-2">
                          <DollarSign size={16} className="text-muted-foreground" />
                          <span>{job.salary}</span>
                        </div>
                      )}
                      
                      <div className="flex items-center space-x-2">
                        <Calendar size={16} className="text-muted-foreground" />
                        <span>Posted: {formatDate(job.postedDate)}</span>
                      </div>
                      
                      {job.appliedDate && (
                        <div className="flex items-center space-x-2">
                          <Calendar size={16} className="text-muted-foreground" />
                          <span>Applied: {formatDate(job.appliedDate)}</span>
                        </div>
                      )}
                    </div>
                    
                    <div className="space-y-3">
                      {job.url && (
                        <div className="flex items-center space-x-2">
                          <Link size={16} className="text-muted-foreground" />
                          <a href={job.url} target="_blank" rel="noopener noreferrer" className="text-primary hover:underline">
                            View Job Posting
                          </a>
                        </div>
                      )}
                      
                      {job.contactName && (
                        <div className="flex items-center space-x-2">
                          <User size={16} className="text-muted-foreground" />
                          <span>{job.contactName}</span>
                        </div>
                      )}
                      
                      {job.contactEmail && (
                        <div className="flex items-center space-x-2">
                          <Mail size={16} className="text-muted-foreground" />
                          <a href={`mailto:${job.contactEmail}`} className="text-primary hover:underline">
                            {job.contactEmail}
                          </a>
                        </div>
                      )}
                      
                      {job.contactPhone && (
                        <div className="flex items-center space-x-2">
                          <Phone size={16} className="text-muted-foreground" />
                          <a href={`tel:${job.contactPhone}`} className="text-primary hover:underline">
                            {job.contactPhone}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </TabsContent>
          
          <TabsContent value="description" className="pt-4 animate-fade-in">
            <div className="space-y-4">
              <h3 className="text-md font-medium flex items-center">
                <FileText size={16} className="mr-2" />
                Job Description
              </h3>
              
              {isEditing ? (
                <div>
                  <Textarea
                    id="description"
                    name="description"
                    value={editedJob?.description || ''}
                    onChange={handleInputChange}
                    placeholder="Job Description - supports HTML formatting"
                    className="min-h-[150px] resize-y"
                  />
                  <p className="text-xs text-muted-foreground mt-1">
                    Supports HTML tags like &lt;b&gt;bold&lt;/b&gt;, &lt;i&gt;italic&lt;/i&gt;, &lt;ul&gt;&lt;li&gt;lists&lt;/li&gt;&lt;/ul&gt;
                  </p>
                </div>
              ) : (
                <div className="text-muted-foreground bg-muted/40 p-4 rounded-md max-h-60 overflow-y-auto prose prose-sm dark:prose-invert prose-p:my-1 prose-headings:mb-2 prose-headings:mt-3 prose-ul:my-1">
                  <div dangerouslySetInnerHTML={{ __html: job.description }} />
                </div>
              )}
            </div>
          </TabsContent>
          
          <TabsContent value="notes" className="pt-4 animate-fade-in">
            <div className="space-y-4">
              <h3 className="text-md font-medium">Communication Log</h3>
              
              <div className="border border-border/60 rounded-md">
                <div className="p-3 border-b border-border/60 bg-muted/30">
                  <h4 className="text-sm font-medium">Add New Note</h4>
                </div>
                <div className="p-3 space-y-3">
                  <div>
                    <Label htmlFor="noteType">Note Type</Label>
                    <Select 
                      value={newNote.type} 
                      onValueChange={(value) => setNewNote({...newNote, type: value as NoteType})}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select note type" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="general">General</SelectItem>
                        <SelectItem value="email">Email</SelectItem>
                        <SelectItem value="phone">Phone Call</SelectItem>
                        <SelectItem value="linkedin">LinkedIn</SelectItem>
                        <SelectItem value="interview">Interview</SelectItem>
                        <SelectItem value="other">Other</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  
                  <div>
                    <Label htmlFor="noteContent">Content</Label>
                    <Textarea
                      id="noteContent"
                      placeholder="Enter your note here..."
                      value={newNote.content}
                      onChange={(e) => setNewNote({...newNote, content: e.target.value})}
                      className="resize-y min-h-[100px]"
                    />
                  </div>
                  
                  <Button 
                    onClick={addNote} 
                    disabled={isAddingNote || !newNote.content.trim()}
                    className="w-full"
                  >
                    {isAddingNote ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Adding...
                      </>
                    ) : (
                      "Add Note"
                    )}
                  </Button>
                </div>
              </div>
              
              {notes.length > 0 ? (
                <div className="relative mt-6">
                  <div className="absolute left-3 top-0 bottom-0 w-0.5 bg-border/60"></div>
                  
                  <div className="space-y-8">
                    {groupNotesByDate().map((group) => (
                      <div key={group.date} className="space-y-4">
                        <div className="relative">
                          <div className="absolute left-0 right-0 flex items-center">
                            <div className="flex-grow h-px bg-border/60"></div>
                          </div>
                          <div className="relative flex justify-center text-xs uppercase">
                            <span className="bg-background px-2 py-1 text-muted-foreground font-medium rounded-full border">
                              {group.formattedDate}
                            </span>
                          </div>
                        </div>
                        
                        <div className="space-y-3 pl-7">
                          {group.notes.map((note) => (
                            <div key={note.id} className="relative pl-7 py-2">
                              <div className="absolute left-0 mt-1 bg-background rounded-full p-1 border -ml-1.5">
                                {getNoteIcon(note.type)}
                              </div>
                              
                              <div className="bg-muted/30 rounded-md p-3 space-y-1">
                                <div className="flex justify-between items-start">
                                  <span className="text-sm font-medium capitalize">{note.type} Note</span>
                                  <span className="text-xs text-muted-foreground">{formatTime(note.timestamp)}</span>
                                </div>
                                
                                <div className="text-sm mt-1 whitespace-pre-wrap">
                                  {note.content}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="text-center py-6 text-muted-foreground bg-muted/20 rounded-md">
                  <MessageSquare className="h-8 w-8 mx-auto text-muted-foreground/50 mb-2" />
                  <p>No notes yet for this job.</p>
                  <p className="text-sm">Add your first note to start tracking communications.</p>
                </div>
              )}
            </div>
          </TabsContent>
          
          <TabsContent value="tasks" className="pt-4 animate-fade-in">
            <div className="space-y-4">
              <h3 className="text-md font-medium">Tasks</h3>
              
              {jobTasks.length > 0 ? (
                <div className="space-y-3">
                  {jobTasks.map((task) => (
                    <TaskItem
                      key={task.id}
                      task={task}
                      onToggleComplete={async () => await toggleTaskCompletion(task.id)}
                      onDelete={async () => await deleteTask(task.id)}
                    />
                  ))}
                </div>
              ) : (
                <div className="text-center py-6 text-muted-foreground">
                  <p>No tasks yet for this job.</p>
                </div>
              )}
              
              <Button 
                variant="outline" 
                size="sm" 
                className="w-full mt-2"
                onClick={() => {
                  addTask({
                    jobId: job.id,
                    title: "New follow-up task",
                    dueDate: new Date().toISOString().split('T')[0],
                    completed: false,
                    priority: 'medium',
                  });
                }}
              >
                Add Task
              </Button>
            </div>
          </TabsContent>
          
          <TabsContent value="activity" className="pt-4 animate-fade-in">
            <div className="space-y-4">
              <h3 className="text-md font-medium flex items-center">
                <Activity size={16} className="mr-2" />
                Job Activity Timeline
              </h3>
              
              {jobActivities.length > 0 ? (
                <div className="relative">
                  <div className="absolute left-3 top-0 bottom-0 w-0.5 bg-border/60"></div>
                  
                  <div className="space-y-8">
                    {groupActivitiesByDate(jobActivities).map((group) => (
                      <div key={group.date} className="space-y-4">
                        <div className="relative">
                          <div className="absolute left-0 right-0 flex items-center">
                            <div className="flex-grow h-px bg-border/60"></div>
                          </div>
                          <div className="relative flex justify-center text-xs uppercase">
                            <span className="bg-background px-2 py-1 text-muted-foreground font-medium rounded-full border">
                              {group.formattedDate}
                            </span>
                          </div>
                        </div>
                        
                        <div className="space-y-3 pl-7">
                          {group.activities.map((activity) => (
                            <div key={activity.id} className="relative pl-7 py-2">
                              <div className="absolute left-0 mt-1 bg-background rounded-full p-1 border -ml-1.5">
                                {getActivityIcon(activity.type)}
                              </div>
                              
                              <div className="bg-muted/30 rounded-md p-3 space-y-1">
                                <div className="flex justify-between items-start">
                                  <span className="text-sm">{activity.message}</span>
                                  <span className="text-xs text-muted-foreground">{formatTime(activity.timestamp)}</span>
                                </div>
                                
                                {activity.details && (
                                  <div className="text-xs text-muted-foreground mt-1">
                                    {activity.details}
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="text-center py-6 text-muted-foreground">
                  <p>No activity recorded yet for this job.</p>
                </div>
              )}
            </div>
          </TabsContent>
        </Tabs>

        <DialogFooter className="sm:justify-between">
          <div className="flex space-x-2">
            <Button
              variant="destructive"
              size="sm"
              onClick={handleDeleteJob}
              disabled={isDeleting || isEditing}
            >
              {isDeleting ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Deleting...
                </>
              ) : (
                "Delete Job"
              )}
            </Button>
          </div>
          <div className="flex space-x-2">
            {isEditing ? (
              <>
                <Button 
                  variant="outline" 
                  size="sm"
                  onClick={handleEditToggle}
                  disabled={isSaving}
                >
                  <X className="mr-1 h-4 w-4" />
                  Cancel
                </Button>
                <Button 
                  size="sm"
                  onClick={handleSaveEdit}
                  disabled={isSaving}
                >
                  {isSaving ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Saving...
                    </>
                  ) : (
                    <>
                      <Save className="mr-1 h-4 w-4" />
                      Save Changes
                    </>
                  )}
                </Button>
              </>
            ) : (
              <>
                <Button 
                  variant="outline" 
                  size="sm"
                  onClick={() => onOpenChange(false)}
                >
                  Close
                </Button>
                <Button 
                  size="sm"
                  onClick={handleEditToggle}
                >
                  Edit Job
                </Button>
              </>
            )}
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default JobDetailModal;
