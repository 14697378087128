
import React from 'react';
import { getBuildInfo } from '@/integrations/supabase/client';
import { Badge } from '@/components/ui/badge';
import { AlertCircle } from 'lucide-react';
import { environment, formatVersion, getEnvironmentName } from '@/environments/index';

interface BuildInfoProps {
  showDetails?: boolean;
}

export const BuildInfo: React.FC<BuildInfoProps> = ({ showDetails = false }) => {
  const buildInfo = getBuildInfo();
  const envName = getEnvironmentName();
  
  // Get appropriate badge variant based on environment
  const getBadgeVariant = () => {
    switch(envName) {
      case 'production':
        return 'default';
      case 'qa':
        return 'outline';
      default:
        return 'secondary';
    }
  };
  
  // Simple badge showing current environment
  if (!showDetails) {
    return (
      <Badge variant={getBadgeVariant()} className="text-xs font-bold">
        {envName.toUpperCase()}
      </Badge>
    );
  }
  
  // Detailed build information
  return (
    <div className="text-xs text-muted-foreground space-y-1">
      <div className="flex items-center space-x-2">
        <Badge variant={getBadgeVariant()} className="text-xs font-bold">
          {envName.toUpperCase()}
        </Badge>
        <span>
          {formatVersion(environment.version)}
        </span>
      </div>
      <div>
        <p>Supabase Instance: {environment.supabaseUrl.replace('https://', '')}</p>
        <p>Build date: {new Date(buildInfo.buildDate).toLocaleString()}</p>
        {envName !== 'production' && (
          <div className="flex items-center mt-1 text-amber-600 dark:text-amber-400">
            <AlertCircle className="h-3 w-3 mr-1" />
            <span>{envName === 'qa' ? 'QA Environment' : 'Development Environment'}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default BuildInfo;
