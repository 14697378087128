
import React, { useState, useEffect } from "react";
import { useJobContext } from "@/context/JobContext";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { 
  Dialog, 
  DialogContent, 
  DialogHeader, 
  DialogTitle, 
  DialogTrigger,
  DialogFooter
} from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Calendar as CalendarIcon, Filter, Plus, SearchIcon, CheckSquare } from "lucide-react";
import { format } from "date-fns";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import StatCard from "@/components/StatCard";
import TaskItem from "@/components/TaskItem";
import { Task, TaskStats } from "@/types";

const TasksPage: React.FC = () => {
  const { tasks, jobs, addTask, updateTask, toggleTaskCompletion, deleteTask } = useJobContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState<string>("all");
  const [dueDateFilter, setDueDateFilter] = useState<string>("all");
  const [priorityFilter, setPriorityFilter] = useState<string>("all");
  const [filteredTasks, setFilteredTasks] = useState<Task[]>([]);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [taskStats, setTaskStats] = useState<TaskStats>({
    total: 0,
    toDo: 0,
    inProgress: 0,
    done: 0,
    blocked: 0,
    dueToday: 0,
    overdue: 0
  });

  // Create task form state
  const [newTask, setNewTask] = useState({
    title: "",
    description: "",
    dueDate: new Date(),
    priority: "medium",
    status: "To Do",
    jobId: ""
  });

  // Filter tasks based on filters
  useEffect(() => {
    let result = tasks;

    // Filter by search query
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      result = result.filter(
        task => 
          task.title.toLowerCase().includes(query) || 
          (task.description && task.description.toLowerCase().includes(query))
      );
    }

    // Filter by status
    if (statusFilter !== "all") {
      if (statusFilter === "completed") {
        result = result.filter(task => task.completed);
      } else if (statusFilter === "not_completed") {
        result = result.filter(task => !task.completed);
      } else {
        result = result.filter(task => task.status === statusFilter || 
          (statusFilter === "To Do" && !task.status && !task.completed));
      }
    }

    // Filter by due date
    if (dueDateFilter !== "all") {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      
      const nextWeek = new Date(today);
      nextWeek.setDate(nextWeek.getDate() + 7);

      switch (dueDateFilter) {
        case "overdue":
          result = result.filter(task => {
            const dueDate = new Date(task.dueDate);
            return !task.completed && dueDate < today;
          });
          break;
        case "today":
          result = result.filter(task => {
            const dueDate = new Date(task.dueDate);
            return dueDate >= today && dueDate < tomorrow;
          });
          break;
        case "this_week":
          result = result.filter(task => {
            const dueDate = new Date(task.dueDate);
            return dueDate >= today && dueDate < nextWeek;
          });
          break;
      }
    }

    // Filter by priority
    if (priorityFilter !== "all") {
      result = result.filter(task => task.priority === priorityFilter);
    }

    // Sort by due date
    result = [...result].sort((a, b) => {
      if (a.completed && !b.completed) return 1;
      if (!a.completed && b.completed) return -1;
      return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
    });

    setFilteredTasks(result);
  }, [tasks, searchQuery, statusFilter, dueDateFilter, priorityFilter]);

  // Calculate task statistics
  useEffect(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const stats: TaskStats = {
      total: tasks.length,
      toDo: tasks.filter(t => (!t.status || t.status === "To Do") && !t.completed).length,
      inProgress: tasks.filter(t => t.status === "In Progress" && !t.completed).length,
      done: tasks.filter(t => t.completed || t.status === "Done").length,
      blocked: tasks.filter(t => t.status === "Blocked" && !t.completed).length,
      dueToday: tasks.filter(t => {
        if (t.completed) return false;
        const dueDate = new Date(t.dueDate);
        return dueDate.getDate() === today.getDate() &&
               dueDate.getMonth() === today.getMonth() &&
               dueDate.getFullYear() === today.getFullYear();
      }).length,
      overdue: tasks.filter(t => {
        if (t.completed) return false;
        return new Date(t.dueDate) < today;
      }).length
    };

    setTaskStats(stats);
  }, [tasks]);

  const handleCreateTask = async () => {
    if (!newTask.title || !newTask.dueDate || !newTask.jobId) {
      return;
    }

    await addTask({
      jobId: newTask.jobId,
      title: newTask.title,
      description: newTask.description,
      dueDate: newTask.dueDate.toISOString(),
      status: newTask.status as any,
      completed: newTask.status === "Done",
      priority: newTask.priority as 'low' | 'medium' | 'high'
    });

    // Reset form
    setNewTask({
      title: "",
      description: "",
      dueDate: new Date(),
      priority: "medium",
      status: "To Do",
      jobId: ""
    });

    setIsCreateDialogOpen(false);
  };

  const getJobTitleById = (jobId: string) => {
    const job = jobs.find(j => j.id === jobId);
    return job ? job.title : "";
  };

  const updateTaskStatus = async (taskId: string, completed: boolean) => {
    await toggleTaskCompletion(taskId);
    
    // If we need to update the status as well
    const task = tasks.find(t => t.id === taskId);
    if (task) {
      const updatedTask = {
        ...task,
        completed,
        status: completed ? "Done" : task.status || "To Do"
      };
      await updateTask(updatedTask);
    }
  };

  return (
    <div className="container mx-auto py-6 space-y-6">
      <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
        <h1 className="text-3xl font-bold tracking-tight">Tasks</h1>
        <Button onClick={() => setIsCreateDialogOpen(true)}>
          <Plus className="mr-2 h-4 w-4" />
          Add Task
        </Button>
      </div>

      {/* Task Stats Cards */}
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <StatCard
          title="Total Tasks"
          value={taskStats.total}
          icon={<CheckSquare className="h-4 w-4" />}
        />
        <StatCard
          title="Due Today"
          value={taskStats.dueToday}
          className="bg-yellow-50"
          trend={taskStats.dueToday > 0 ? "up" : "neutral"}
          description={taskStats.dueToday > 0 ? "Needs attention" : "All clear"}
        />
        <StatCard
          title="Overdue"
          value={taskStats.overdue}
          className="bg-red-50"
          trend={taskStats.overdue > 0 ? "up" : "neutral"}
          description={taskStats.overdue > 0 ? "Past due" : "All clear"}
        />
        <StatCard
          title="Completed"
          value={taskStats.done}
          className="bg-green-50"
          trend={"neutral"}
        />
      </div>

      {/* Tasks Filters */}
      <div className="flex flex-col gap-4 md:flex-row md:items-center">
        <div className="relative flex-1">
          <SearchIcon className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            type="search"
            placeholder="Search tasks..."
            className="pl-8"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        
        <div className="flex flex-wrap gap-2">
          <Select value={statusFilter} onValueChange={setStatusFilter}>
            <SelectTrigger className="w-[130px]">
              <Filter className="mr-2 h-4 w-4" />
              <SelectValue placeholder="Status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All Statuses</SelectItem>
              <SelectItem value="To Do">To Do</SelectItem>
              <SelectItem value="In Progress">In Progress</SelectItem>
              <SelectItem value="Done">Done</SelectItem>
              <SelectItem value="Blocked">Blocked</SelectItem>
              <SelectItem value="completed">Completed</SelectItem>
              <SelectItem value="not_completed">Not Completed</SelectItem>
            </SelectContent>
          </Select>

          <Select value={dueDateFilter} onValueChange={setDueDateFilter}>
            <SelectTrigger className="w-[130px]">
              <CalendarIcon className="mr-2 h-4 w-4" />
              <SelectValue placeholder="Due Date" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All Dates</SelectItem>
              <SelectItem value="overdue">Overdue</SelectItem>
              <SelectItem value="today">Due Today</SelectItem>
              <SelectItem value="this_week">This Week</SelectItem>
            </SelectContent>
          </Select>

          <Select value={priorityFilter} onValueChange={setPriorityFilter}>
            <SelectTrigger className="w-[130px]">
              <Filter className="mr-2 h-4 w-4" />
              <SelectValue placeholder="Priority" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All Priorities</SelectItem>
              <SelectItem value="high">High</SelectItem>
              <SelectItem value="medium">Medium</SelectItem>
              <SelectItem value="low">Low</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      {/* Tasks List */}
      <Tabs defaultValue="all" className="w-full">
        <TabsList className="grid w-full grid-cols-5">
          <TabsTrigger value="all">All Tasks</TabsTrigger>
          <TabsTrigger value="todo">To Do ({taskStats.toDo})</TabsTrigger>
          <TabsTrigger value="in-progress">In Progress ({taskStats.inProgress})</TabsTrigger>
          <TabsTrigger value="done">Done ({taskStats.done})</TabsTrigger>
          <TabsTrigger value="blocked">Blocked ({taskStats.blocked})</TabsTrigger>
        </TabsList>
        
        <TabsContent value="all" className="mt-6">
          <Card>
            <CardHeader>
              <CardTitle>All Tasks ({filteredTasks.length})</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              {filteredTasks.length === 0 ? (
                <p className="text-center text-muted-foreground py-8">No tasks found. Try adjusting your filters.</p>
              ) : (
                filteredTasks.map(task => (
                  <TaskItem
                    key={task.id}
                    task={task}
                    jobTitle={getJobTitleById(task.jobId)}
                    onToggleComplete={() => updateTaskStatus(task.id, !task.completed)}
                    onDelete={() => deleteTask(task.id)}
                  />
                ))
              )}
            </CardContent>
          </Card>
        </TabsContent>
        
        <TabsContent value="todo" className="mt-6">
          <Card>
            <CardHeader>
              <CardTitle>To Do Tasks</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              {filteredTasks.filter(t => (!t.status || t.status === "To Do") && !t.completed).length === 0 ? (
                <p className="text-center text-muted-foreground py-8">No to-do tasks found.</p>
              ) : (
                filteredTasks
                  .filter(t => (!t.status || t.status === "To Do") && !t.completed)
                  .map(task => (
                    <TaskItem
                      key={task.id}
                      task={task}
                      jobTitle={getJobTitleById(task.jobId)}
                      onToggleComplete={() => updateTaskStatus(task.id, !task.completed)}
                      onDelete={() => deleteTask(task.id)}
                    />
                  ))
              )}
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="in-progress" className="mt-6">
          <Card>
            <CardHeader>
              <CardTitle>In Progress Tasks</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              {filteredTasks.filter(t => t.status === "In Progress" && !t.completed).length === 0 ? (
                <p className="text-center text-muted-foreground py-8">No in-progress tasks found.</p>
              ) : (
                filteredTasks
                  .filter(t => t.status === "In Progress" && !t.completed)
                  .map(task => (
                    <TaskItem
                      key={task.id}
                      task={task}
                      jobTitle={getJobTitleById(task.jobId)}
                      onToggleComplete={() => updateTaskStatus(task.id, !task.completed)}
                      onDelete={() => deleteTask(task.id)}
                    />
                  ))
              )}
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="done" className="mt-6">
          <Card>
            <CardHeader>
              <CardTitle>Completed Tasks</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              {filteredTasks.filter(t => t.completed || t.status === "Done").length === 0 ? (
                <p className="text-center text-muted-foreground py-8">No completed tasks found.</p>
              ) : (
                filteredTasks
                  .filter(t => t.completed || t.status === "Done")
                  .map(task => (
                    <TaskItem
                      key={task.id}
                      task={task}
                      jobTitle={getJobTitleById(task.jobId)}
                      onToggleComplete={() => updateTaskStatus(task.id, !task.completed)}
                      onDelete={() => deleteTask(task.id)}
                    />
                  ))
              )}
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="blocked" className="mt-6">
          <Card>
            <CardHeader>
              <CardTitle>Blocked Tasks</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              {filteredTasks.filter(t => t.status === "Blocked" && !t.completed).length === 0 ? (
                <p className="text-center text-muted-foreground py-8">No blocked tasks found.</p>
              ) : (
                filteredTasks
                  .filter(t => t.status === "Blocked" && !t.completed)
                  .map(task => (
                    <TaskItem
                      key={task.id}
                      task={task}
                      jobTitle={getJobTitleById(task.jobId)}
                      onToggleComplete={() => updateTaskStatus(task.id, !task.completed)}
                      onDelete={() => deleteTask(task.id)}
                    />
                  ))
              )}
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>

      {/* Create Task Dialog */}
      <Dialog open={isCreateDialogOpen} onOpenChange={setIsCreateDialogOpen}>
        <DialogContent className="sm:max-w-[500px]">
          <DialogHeader>
            <DialogTitle>Create New Task</DialogTitle>
          </DialogHeader>
          
          <div className="grid gap-4 py-4">
            <div className="grid gap-2">
              <Label htmlFor="task-title">Title</Label>
              <Input
                id="task-title"
                value={newTask.title}
                onChange={(e) => setNewTask({...newTask, title: e.target.value})}
                placeholder="Enter task title"
              />
            </div>

            <div className="grid gap-2">
              <Label htmlFor="task-description">Description</Label>
              <Textarea
                id="task-description"
                value={newTask.description}
                onChange={(e) => setNewTask({...newTask, description: e.target.value})}
                placeholder="Enter task description"
                rows={3}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label>Due Date</Label>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      className="justify-start text-left font-normal"
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {newTask.dueDate ? format(newTask.dueDate, "PPP") : "Select date"}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0">
                    <Calendar
                      mode="single"
                      selected={newTask.dueDate}
                      onSelect={(date) => date && setNewTask({...newTask, dueDate: date})}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
              </div>

              <div className="grid gap-2">
                <Label htmlFor="task-priority">Priority</Label>
                <Select 
                  value={newTask.priority} 
                  onValueChange={(value) => setNewTask({...newTask, priority: value})}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select priority" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="low">Low</SelectItem>
                    <SelectItem value="medium">Medium</SelectItem>
                    <SelectItem value="high">High</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="task-status">Status</Label>
                <Select 
                  value={newTask.status} 
                  onValueChange={(value) => setNewTask({...newTask, status: value})}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select status" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="To Do">To Do</SelectItem>
                    <SelectItem value="In Progress">In Progress</SelectItem>
                    <SelectItem value="Done">Done</SelectItem>
                    <SelectItem value="Blocked">Blocked</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <div className="grid gap-2">
                <Label htmlFor="task-job">Related Job</Label>
                <Select 
                  value={newTask.jobId} 
                  onValueChange={(value) => setNewTask({...newTask, jobId: value})}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select job" />
                  </SelectTrigger>
                  <SelectContent>
                    {jobs.map(job => (
                      <SelectItem key={job.id} value={job.id}>
                        {job.title} at {job.company}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>

          <DialogFooter>
            <Button variant="outline" onClick={() => setIsCreateDialogOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handleCreateTask}>
              Create Task
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TasksPage;
