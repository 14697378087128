
import React from 'react';
import { Link } from 'react-router-dom';
import { useJobContext } from '@/context/JobContext';
import StatCard from '@/components/StatCard';
import JobCard from '@/components/JobCard';
import JobDetailModal from '@/components/JobDetailModal';
import TaskItem from '@/components/TaskItem';
import { Button } from '@/components/ui/button';
import { 
  Briefcase, 
  CheckSquare, 
  Clock, 
  LayoutDashboard, 
  Send, 
  MessageSquare, 
  Award, 
  XCircle,
  Plus,
} from 'lucide-react';

const Index = () => {
  const { 
    jobs, 
    tasks,
    stats, 
    selectedJob, 
    setSelectedJob,
    toggleTaskCompletion,
    deleteTask
  } = useJobContext();
  
  // Filter for dashboard display
  const upcomingTasks = tasks
    .filter(task => !task.completed && new Date(task.dueDate) >= new Date())
    .sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime())
    .slice(0, 5);
  
  const recentJobs = jobs
    .sort((a, b) => new Date(b.postedDate).getTime() - new Date(a.postedDate).getTime())
    .slice(0, 3);

  return (
    <div>
      <div className="mb-8">
        <h1 className="text-3xl font-bold tracking-tight">Dashboard</h1>
        <p className="text-muted-foreground mt-1">
          Track your job search progress and stay organized
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <StatCard 
          title="Total Jobs" 
          value={stats.totalJobs} 
          icon={<Briefcase size={20} />}
        />
        <StatCard 
          title="Applied" 
          value={stats.appliedJobs} 
          icon={<Send size={20} />}
        />
        <StatCard 
          title="Interviews" 
          value={stats.interviewingJobs} 
          icon={<MessageSquare size={20} />}
        />
        <StatCard 
          title="Offers" 
          value={stats.offers} 
          icon={<Award size={20} />}
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-8">
        {/* Jobs Overview */}
        <div className="lg:col-span-2 bg-white rounded-lg border border-border/60 overflow-hidden">
          <div className="p-5 border-b border-border/60 flex justify-between items-center">
            <h2 className="font-semibold flex items-center">
              <Briefcase size={18} className="mr-2" />
              Recent Jobs
            </h2>
            <Link to="/kanban">
              <Button variant="ghost" size="sm" className="text-sm">
                View All
              </Button>
            </Link>
          </div>
          
          {recentJobs.length > 0 ? (
            <div className="divide-y divide-border/60">
              {recentJobs.map((job) => (
                <div key={job.id} className="p-4 hover:bg-muted/30 transition-colors">
                  <JobCard job={job} onClick={setSelectedJob} className="shadow-none border-0" />
                </div>
              ))}
            </div>
          ) : (
            <div className="p-8 text-center text-muted-foreground">
              <p>No jobs added yet</p>
              <Button variant="outline" size="sm" className="mt-2">
                <Plus size={16} className="mr-1" />
                Add Your First Job
              </Button>
            </div>
          )}
        </div>

        {/* Tasks Overview */}
        <div className="bg-white rounded-lg border border-border/60 overflow-hidden">
          <div className="p-5 border-b border-border/60 flex justify-between items-center">
            <h2 className="font-semibold flex items-center">
              <CheckSquare size={18} className="mr-2" />
              Upcoming Tasks
            </h2>
            <Link to="/tasks">
              <Button variant="ghost" size="sm" className="text-sm">
                View All
              </Button>
            </Link>
          </div>
          
          {upcomingTasks.length > 0 ? (
            <div className="p-4 space-y-3">
              {upcomingTasks.map((task) => (
                <TaskItem
                  key={task.id}
                  task={task}
                  onToggleComplete={() => toggleTaskCompletion(task.id)}
                  onDelete={() => deleteTask(task.id)}
                />
              ))}
            </div>
          ) : (
            <div className="p-8 text-center text-muted-foreground">
              <p>No upcoming tasks</p>
              <Button variant="outline" size="sm" className="mt-2">
                <Plus size={16} className="mr-1" />
                Add Task
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
        <div className="bg-white rounded-lg border border-border/60 p-5">
          <h2 className="font-semibold mb-4 flex items-center">
            <Clock size={18} className="mr-2" />
            Application Status
          </h2>
          <div className="space-y-3">
            <div className="flex items-center justify-between">
              <span className="text-sm">New</span>
              <div className="flex-1 mx-4">
                <div className="h-2 bg-muted rounded-full overflow-hidden">
                  <div
                    className="h-full bg-blue-400"
                    style={{ width: `${(stats.newJobs / stats.totalJobs) * 100}%` }}
                  ></div>
                </div>
              </div>
              <span className="font-medium">{stats.newJobs}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm">Applied</span>
              <div className="flex-1 mx-4">
                <div className="h-2 bg-muted rounded-full overflow-hidden">
                  <div
                    className="h-full bg-purple-400"
                    style={{ width: `${(stats.appliedJobs / stats.totalJobs) * 100}%` }}
                  ></div>
                </div>
              </div>
              <span className="font-medium">{stats.appliedJobs}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm">Interviewing</span>
              <div className="flex-1 mx-4">
                <div className="h-2 bg-muted rounded-full overflow-hidden">
                  <div
                    className="h-full bg-cyan-400"
                    style={{ width: `${(stats.interviewingJobs / stats.totalJobs) * 100}%` }}
                  ></div>
                </div>
              </div>
              <span className="font-medium">{stats.interviewingJobs}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm">Negotiating</span>
              <div className="flex-1 mx-4">
                <div className="h-2 bg-muted rounded-full overflow-hidden">
                  <div
                    className="h-full bg-yellow-400"
                    style={{ width: `${(stats.negotiatingJobs / stats.totalJobs) * 100}%` }}
                  ></div>
                </div>
              </div>
              <span className="font-medium">{stats.negotiatingJobs}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm">Offers</span>
              <div className="flex-1 mx-4">
                <div className="h-2 bg-muted rounded-full overflow-hidden">
                  <div
                    className="h-full bg-green-400"
                    style={{ width: `${(stats.offers / stats.totalJobs) * 100}%` }}
                  ></div>
                </div>
              </div>
              <span className="font-medium">{stats.offers}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm">Declined</span>
              <div className="flex-1 mx-4">
                <div className="h-2 bg-muted rounded-full overflow-hidden">
                  <div
                    className="h-full bg-red-400"
                    style={{ width: `${(stats.declined / stats.totalJobs) * 100}%` }}
                  ></div>
                </div>
              </div>
              <span className="font-medium">{stats.declined}</span>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg border border-border/60 p-5">
          <h2 className="font-semibold mb-4 flex items-center">
            <LayoutDashboard size={18} className="mr-2" />
            Application Summary
          </h2>
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-blue-50 rounded-lg p-4 border border-blue-100">
              <h3 className="text-sm font-medium text-blue-800">Application Rate</h3>
              <p className="text-2xl font-semibold text-blue-900">
                {stats.totalJobs > 0 
                  ? ((stats.appliedJobs / stats.totalJobs) * 100).toFixed(0) 
                  : 0}%
              </p>
              <p className="text-xs text-blue-700 mt-1">
                {stats.appliedJobs} of {stats.totalJobs} jobs
              </p>
            </div>
            <div className="bg-purple-50 rounded-lg p-4 border border-purple-100">
              <h3 className="text-sm font-medium text-purple-800">Interview Rate</h3>
              <p className="text-2xl font-semibold text-purple-900">
                {stats.appliedJobs > 0 
                  ? ((stats.interviewingJobs / stats.appliedJobs) * 100).toFixed(0) 
                  : 0}%
              </p>
              <p className="text-xs text-purple-700 mt-1">
                {stats.interviewingJobs} of {stats.appliedJobs} applications
              </p>
            </div>
            <div className="bg-green-50 rounded-lg p-4 border border-green-100">
              <h3 className="text-sm font-medium text-green-800">Offer Rate</h3>
              <p className="text-2xl font-semibold text-green-900">
                {stats.interviewingJobs > 0 
                  ? ((stats.offers / stats.interviewingJobs) * 100).toFixed(0) 
                  : 0}%
              </p>
              <p className="text-xs text-green-700 mt-1">
                {stats.offers} of {stats.interviewingJobs} interviews
              </p>
            </div>
            <div className="bg-red-50 rounded-lg p-4 border border-red-100">
              <h3 className="text-sm font-medium text-red-800">Rejection Rate</h3>
              <p className="text-2xl font-semibold text-red-900">
                {stats.totalJobs > 0 
                  ? ((stats.declined / stats.totalJobs) * 100).toFixed(0) 
                  : 0}%
              </p>
              <p className="text-xs text-red-700 mt-1">
                {stats.declined} of {stats.totalJobs} jobs
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Task Reminders */}
      {stats.tasksPastDue > 0 && (
        <div className="bg-red-50 border border-red-200 rounded-lg p-5 mb-8 animate-pulse-subtle">
          <div className="flex items-start">
            <div className="bg-red-100 rounded-full p-2 mr-4">
              <XCircle size={20} className="text-red-600" />
            </div>
            <div>
              <h3 className="font-medium text-red-800">Overdue Tasks</h3>
              <p className="text-sm text-red-700 mt-1">
                You have {stats.tasksPastDue} {stats.tasksPastDue === 1 ? 'task' : 'tasks'} that {stats.tasksPastDue === 1 ? 'is' : 'are'} past due.
              </p>
            </div>
            <div className="ml-auto">
              <Button variant="outline" size="sm" className="border-red-300 text-red-600 hover:bg-red-100">
                View Tasks
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Job Detail Modal */}
      <JobDetailModal 
        job={selectedJob} 
        open={!!selectedJob} 
        onOpenChange={(open) => !open && setSelectedJob(null)} 
      />
    </div>
  );
};

export default Index;
