
import React from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { useTheme } from "@/components/theme-provider";
import BuildInfo from '@/components/BuildInfo';
import { environment } from '@/environments/index';
import { isAuthRequired, isProduction } from '@/integrations/supabase/client';
import ConnectionStatus from '@/components/ConnectionStatus';
import EnvironmentIndicator from '@/components/EnvironmentIndicator';
import EnvironmentDebugger from '@/components/EnvironmentDebugger';
import VersionDebugger from '@/components/VersionDebugger';

const Settings = () => {
  const { setTheme, theme } = useTheme();
  
  // Format version to always include "v" prefix
  const formatVersion = (version: string) => {
    if (!version) return 'v0.1.0';
    return version.startsWith('v') ? version : `v${version}`;
  };
  
  return (
    <div className="container mx-auto py-6">
      <h1 className="text-3xl font-bold mb-6">Settings</h1>
      
      <Tabs defaultValue="appearance">
        <TabsList className="mb-6">
          <TabsTrigger value="appearance">Appearance</TabsTrigger>
          <TabsTrigger value="notifications">Notifications</TabsTrigger>
          <TabsTrigger value="about">About</TabsTrigger>
          <TabsTrigger value="debug">Debug</TabsTrigger>
        </TabsList>
        
        <TabsContent value="appearance">
          <Card>
            <CardHeader>
              <CardTitle>Appearance</CardTitle>
              <CardDescription>
                Customize how the application looks on your device
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="flex items-center justify-between">
                <div className="space-y-0.5">
                  <Label htmlFor="darkMode">Dark Mode</Label>
                  <p className="text-sm text-muted-foreground">
                    Switch between light and dark theme
                  </p>
                </div>
                <Switch
                  id="darkMode"
                  checked={theme === "dark"}
                  onCheckedChange={(checked) => setTheme(checked ? "dark" : "light")}
                />
              </div>
            </CardContent>
            <CardFooter>
              <Button variant="outline" onClick={() => setTheme("system")}>Reset to System</Button>
            </CardFooter>
          </Card>
        </TabsContent>
        
        <TabsContent value="notifications">
          <Card>
            <CardHeader>
              <CardTitle>Notifications</CardTitle>
              <CardDescription>
                Configure your notification preferences
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="flex items-center justify-between">
                <div className="space-y-0.5">
                  <Label htmlFor="emailNotifications">Email Notifications</Label>
                  <p className="text-sm text-muted-foreground">
                    Receive email notifications for important updates
                  </p>
                </div>
                <Switch id="emailNotifications" defaultChecked />
              </div>
              <div className="flex items-center justify-between">
                <div className="space-y-0.5">
                  <Label htmlFor="pushNotifications">Push Notifications</Label>
                  <p className="text-sm text-muted-foreground">
                    Receive push notifications for important updates
                  </p>
                </div>
                <Switch id="pushNotifications" />
              </div>
            </CardContent>
          </Card>
        </TabsContent>
        
        <TabsContent value="about">
          <Card>
            <CardHeader>
              <CardTitle>About LadderingUp</CardTitle>
              <CardDescription>
                Application information and build details
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="rounded-md bg-muted p-6">
                <BuildInfo showDetails={true} />
              </div>
              
              <div className="space-y-2 mt-6">
                <h3 className="text-lg font-medium">Environment Details</h3>
                <div className="grid grid-cols-2 gap-2 text-sm">
                  <div className="font-medium">API URL:</div>
                  <div>{environment.apiUrl}</div>
                  
                  <div className="font-medium">Supabase URL:</div>
                  <div className="truncate">{environment.supabaseUrl}</div>
                 
                  <div className="font-medium">Version:</div>
                  <div>{formatVersion(environment.version)}</div>
                  
                  <div className="font-medium">Production Mode:</div>
                  <div>{environment.production ? 'Yes' : 'No'}</div>
                  
                  <div className="font-medium">Authentication Required:</div>
                  <div>
                    {isAuthRequired() ? 
                      <Badge variant="default" className="bg-green-500">Yes</Badge> : 
                      <Badge variant="outline">No</Badge>
                    }
                  </div>
                  
                  <div className="font-medium">Build Date:</div>
                  <div>{new Date(environment.buildDate).toLocaleString()}</div>
                </div>
              </div>
              
              <div className="mt-8">
                <h3 className="text-lg font-medium mb-4">Environment Debugger</h3>
                <EnvironmentDebugger />
              </div>
            </CardContent>
            <CardFooter>
              <a 
                href="https://github.com/ladderingup/ladderingup" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-sm text-blue-600 hover:underline"
              >
                View on GitHub
              </a>
            </CardFooter>
          </Card>
        </TabsContent>
        
        <TabsContent value="debug">
          <div className="space-y-6">
            <Card>
              <CardHeader>
                <CardTitle>Version Debugging</CardTitle>
                <CardDescription>
                  Diagnose version discrepancies between code and deployed application
                </CardDescription>
              </CardHeader>
              <CardContent>
                <VersionDebugger />
              </CardContent>
            </Card>
            
            <Card>
              <CardHeader>
                <CardTitle>Connection Status</CardTitle>
                <CardDescription>
                  Test connectivity to backend services
                </CardDescription>
              </CardHeader>
              <CardContent>
                <ConnectionStatus showDetails={true} />
              </CardContent>
            </Card>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default Settings;
