
import React from "react";
import { Job } from "@/types";
import { cn } from "@/lib/utils";
import { Calendar, MapPin, DollarSign } from "lucide-react";

interface JobCardProps {
  job: Job;
  onClick: (job: Job) => void;
  className?: string;
}

const JobCard: React.FC<JobCardProps> = ({ job, onClick, className }) => {
  // Format date to be more readable
  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'new': return 'bg-status-new border-blue-300';
      case 'applied': return 'bg-status-applied border-purple-300';
      case 'interviewing': return 'bg-status-interviewing border-cyan-300';
      case 'negotiating': return 'bg-status-negotiating border-yellow-300';
      case 'offer': return 'bg-status-offer border-green-300';
      case 'declined': return 'bg-status-declined border-red-300';
      default: return 'bg-gray-100 border-gray-300';
    }
  };

  return (
    <div
      className={cn(
        "bg-white rounded-lg p-4 card-shadow cursor-pointer transition-all duration-300 border border-border/60",
        className
      )}
      onClick={() => onClick(job)}
    >
      <div className="flex items-start justify-between">
        <div className="flex items-center space-x-3">
          {job.logo ? (
            <img
              src={job.logo}
              alt={`${job.company} logo`}
              className="w-10 h-10 rounded-md object-cover"
              loading="lazy"
            />
          ) : (
            <div className="w-10 h-10 rounded-md bg-primary/10 flex items-center justify-center text-primary font-medium">
              {job.company.charAt(0)}
            </div>
          )}
          <div>
            <h3 className="font-medium truncate max-w-[180px]" title={job.title}>
              {job.title}
            </h3>
            <p className="text-sm text-muted-foreground">{job.company}</p>
          </div>
        </div>
        <span
          className={cn(
            "text-xs px-2 py-1 rounded-full border",
            getStatusColor(job.status)
          )}
        >
          {job.status.charAt(0).toUpperCase() + job.status.slice(1)}
        </span>
      </div>

      <div className="mt-3 space-y-2">
        <div className="flex items-center text-sm text-muted-foreground">
          <MapPin size={14} className="mr-1" />
          <span className="truncate">{job.location}</span>
        </div>
        
        {job.salary && (
          <div className="flex items-center text-sm text-muted-foreground">
            <DollarSign size={14} className="mr-1" />
            <span>{job.salary}</span>
          </div>
        )}
        
        <div className="flex items-center text-sm text-muted-foreground">
          <Calendar size={14} className="mr-1" />
          <span>
            {job.appliedDate 
              ? `Applied ${formatDate(job.appliedDate)}` 
              : `Posted ${formatDate(job.postedDate)}`}
          </span>
        </div>
      </div>
    </div>
  );
};

export default JobCard;
