import React, { useState, useEffect } from "react";
import {
  HomeIcon,
  LayoutIcon,
  CheckSquareIcon,
  BuildingIcon,
  SettingsIcon,
  FileTextIcon,
  ShieldIcon,
  MenuIcon,
  UserIcon
} from "lucide-react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "@/context/AuthContext";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { useTheme } from "@/components/theme-provider";
import BuildInfo from '@/components/BuildInfo';
import { environment, formatVersion, getEnvironmentName } from '@/environments/index';
import MobileNavigation from '@/components/MobileNavigation';
import { useIsMobile } from "@/hooks/use-mobile";
import { supabase } from "@/integrations/supabase/client";
import RoleBadge from "@/components/RoleBadge";

const Layout: React.FC = () => {
  const { user, signOut } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const { setTheme, theme } = useTheme();
  const isMobile = useIsMobile();
  
  const displayVersion = formatVersion(environment.version);
  const envName = getEnvironmentName();
  
  const getUserInitials = () => {
    if (!user) return '?';
    
    const firstName = user.user_metadata?.first_name || user.user_metadata?.name?.split(' ')[0] || '';
    const lastName = user.user_metadata?.last_name || user.user_metadata?.name?.split(' ').slice(-1)[0] || '';
    
    if (firstName && lastName) {
      return `${firstName[0]}${lastName[0]}`.toUpperCase();
    }
    
    if (user.email) {
      return user.email.substring(0, 2).toUpperCase();
    }
    
    return '?';
  };

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!user) {
        console.log("No user found, can't check admin status");
        return;
      }
      
      try {
        console.log("Checking admin status for user:", user.id);
        const { data, error } = await supabase.rpc('has_role', {
          _user_id: user.id,
          _role: 'admin'
        });
        
        if (error) {
          console.error('Error checking admin status:', error);
          throw error;
        }
        
        console.log("Admin status response:", data);
        setIsAdmin(!!data);
      } catch (error) {
        console.error('Error checking admin status:', error);
        setIsAdmin(false);
      }
    };

    checkAdminStatus();
  }, [user]);

  const navigationItems = [
    { name: "Dashboard", path: "/", icon: <HomeIcon className="h-5 w-5" /> },
    { name: "Job Board", path: "/kanban", icon: <LayoutIcon className="h-5 w-5" /> },
    { name: "Tasks", path: "/tasks", icon: <CheckSquareIcon className="h-5 w-5" /> },
    { name: "Companies", path: "/companies", icon: <BuildingIcon className="h-5 w-5" /> },
    { name: "Reports", path: "/reports", icon: <FileTextIcon className="h-5 w-5" /> },
  ];

  const adminItems = [
    { name: "Role Management", path: "/admin/roles", icon: <ShieldIcon className="h-5 w-5" /> },
  ];

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="min-h-screen flex flex-col bg-background">
      <div className="flex flex-1 overflow-hidden">
        <div
          className={`fixed left-0 top-0 bottom-0 z-40 bg-slate-900 text-white transition-all duration-300 
          ${isSidebarOpen && !isMobile ? 'w-64' : 'w-0 -translate-x-full'} 
          md:relative md:translate-x-0 md:w-64 hidden md:flex`}
        >
          <div className="h-full flex flex-col">
            <div className="p-4 flex items-center">
              <span className="self-center whitespace-nowrap text-xl font-semibold">
                LadderingUp
              </span>
            </div>
            
            <nav className="flex-1 px-3 py-4 space-y-1 overflow-y-auto">
              {navigationItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.path}
                  className={`flex items-center px-3 py-2 rounded-md text-sm font-medium transition-colors ${
                    location.pathname === item.path
                      ? 'bg-blue-600 text-white'
                      : 'text-gray-300 hover:bg-slate-800 hover:text-white'
                  }`}
                >
                  <span className="mr-3">{item.icon}</span>
                  <span>{item.name}</span>
                </Link>
              ))}

              {isAdmin && (
                <>
                  <div className="pt-4 pb-2">
                    <div className="px-3 text-xs uppercase tracking-wider text-gray-400">
                      Admin
                    </div>
                  </div>
                  
                  {adminItems.map((item) => (
                    <Link
                      key={item.name}
                      to={item.path}
                      className={`flex items-center px-3 py-2 rounded-md text-sm font-medium transition-colors ${
                        location.pathname === item.path
                          ? 'bg-blue-600 text-white'
                          : 'text-gray-300 hover:bg-slate-800 hover:text-white'
                      }`}
                    >
                      <span className="mr-3">{item.icon}</span>
                      <span>{item.name}</span>
                    </Link>
                  ))}
                </>
              )}
            </nav>
          </div>
        </div>

        <div className="flex flex-col flex-1 w-full overflow-x-hidden">
          <header className="bg-slate-800 text-white h-16 flex items-center justify-between px-4 shadow-md">
            <div className="flex items-center">
              <Button variant="ghost" size="icon" className="md:hidden text-white" onClick={toggleSidebar}>
                <MenuIcon className="h-5 w-5" />
              </Button>
              <span className="text-sm font-medium text-slate-300 ml-2">
                {displayVersion}
                <span className="ml-2 text-xs">({envName})</span>
              </span>
            </div>
            
            {user && (
              <div className="flex items-center gap-2">
                {isAdmin && <RoleBadge role="admin" />}
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" className="relative h-8 w-8 rounded-full">
                      <Avatar className="h-8 w-8">
                        <AvatarImage 
                          src={user.user_metadata?.avatar_url} 
                          alt={user.email || ""}
                        />
                        <AvatarFallback className="bg-blue-500 text-white flex items-center justify-center">
                          {getUserInitials()}
                        </AvatarFallback>
                      </Avatar>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-56" align="end" forceMount>
                    <DropdownMenuLabel className="font-normal">
                      <div className="flex flex-col space-y-1">
                        <p className="text-sm font-medium leading-none">
                          {user.user_metadata?.name || 'User Account'}
                        </p>
                      </div>
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem onClick={() => navigate('/profile')}>
                      <UserIcon className="mr-2 h-4 w-4" />
                      <span>Profile</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => navigate('/settings')}>
                      <SettingsIcon className="mr-2 h-4 w-4" />
                      <span>Settings</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => signOut()}>
                      Log out
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            )}
          </header>

          <main className="flex-1 overflow-y-auto bg-slate-100 dark:bg-slate-900 p-6 pb-20 md:pb-6">
            <Outlet />
          </main>

          <MobileNavigation />
        </div>
      </div>
    </div>
  );
};

export default Layout;
