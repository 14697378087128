
import React, { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Company } from "@/types";
import { useAuth } from "@/context/AuthContext";
import { Building2, Globe, Briefcase, MapPin, Calendar, LoaderCircle, Search } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import CompanySearch from "@/components/company-search";
import { toast } from "@/hooks/use-toast";

const CompaniesPage: React.FC = () => {
  const [companies, setCompanies] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [addingCompany, setAddingCompany] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    fetchCompanies();
  }, [user]);

  const fetchCompanies = async () => {
    if (!user) return;
    
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('companies')
        .select('*')
        .order('created_at', { ascending: false });
      
      if (error) throw error;
      
      setCompanies(data || []);
    } catch (error: any) {
      console.error("Error fetching companies:", error);
      toast({
        title: "Error",
        description: "Failed to load companies",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const filteredCompanies = companies.filter(company => 
    company.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (company.industry && company.industry.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (company.website && company.website.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const handleCompanyAdded = async (_companyId: string, _companyName: string) => {
    // Refresh companies list after adding
    await fetchCompanies();
    setAddingCompany(false);
    toast({
      title: "Company Added",
      description: "The company has been added to your database",
    });
  };

  return (
    <div className="container py-6 mx-auto space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Companies</h1>
        <Button onClick={() => setAddingCompany(!addingCompany)}>
          {addingCompany ? "Cancel" : "Add Company"}
        </Button>
      </div>

      {addingCompany && (
        <div className="p-4 border rounded-lg shadow-sm bg-card">
          <h2 className="text-lg font-medium mb-4">Search for a Company</h2>
          <CompanySearch onCompanySelect={handleCompanyAdded} />
        </div>
      )}

      <div className="relative">
        <Search className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
        <Input
          placeholder="Search companies..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="pl-9"
        />
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <LoaderCircle className="h-8 w-8 animate-spin text-primary" />
        </div>
      ) : filteredCompanies.length > 0 ? (
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {filteredCompanies.map(company => (
            <div key={company.id} className="p-4 border rounded-lg shadow-sm hover:shadow-md transition-shadow">
              <div className="flex items-start space-x-3">
                {company.logo ? (
                  <img 
                    src={company.logo} 
                    alt={`${company.name} logo`} 
                    className="w-12 h-12 rounded-md object-contain"
                  />
                ) : (
                  <div className="w-12 h-12 rounded-md bg-primary/10 flex items-center justify-center text-primary font-medium text-xl">
                    {company.name.charAt(0)}
                  </div>
                )}
                <div>
                  <h3 className="font-medium">{company.display_name || company.name}</h3>
                  {company.industry && (
                    <p className="text-sm text-muted-foreground">{company.industry}</p>
                  )}
                </div>
              </div>
              
              <div className="mt-4 space-y-2 text-sm">
                {company.website && (
                  <div className="flex items-center">
                    <Globe className="h-4 w-4 mr-2 text-muted-foreground" />
                    <a 
                      href={company.website.startsWith('http') ? company.website : `https://${company.website}`} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="text-primary hover:underline"
                    >
                      {company.website}
                    </a>
                  </div>
                )}
                
                {company.location && (
                  <div className="flex items-center">
                    <MapPin className="h-4 w-4 mr-2 text-muted-foreground" />
                    <span>{company.location}</span>
                  </div>
                )}
                
                {company.founded && (
                  <div className="flex items-center">
                    <Calendar className="h-4 w-4 mr-2 text-muted-foreground" />
                    <span>Founded: {company.founded}</span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-16 border rounded-md bg-background">
          <Building2 className="mx-auto h-12 w-12 text-muted-foreground/50" />
          <h3 className="mt-4 text-lg font-medium">No companies found</h3>
          <p className="text-muted-foreground mt-2">
            {searchTerm ? "Try a different search term" : "You haven't added any companies yet"}
          </p>
          {!searchTerm && (
            <Button 
              variant="outline" 
              className="mt-4"
              onClick={() => setAddingCompany(true)}
            >
              Add Your First Company
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default CompaniesPage;
