
import React from "react";
import { Building2, Globe, Briefcase, MapPin } from "lucide-react";
import { CommandItem } from "@/components/ui/command";
import { PDLCompany } from "./types";

interface CompanyItemProps {
  company: PDLCompany;
  onSelect: (company: PDLCompany) => void;
}

const CompanyItem: React.FC<CompanyItemProps> = ({ company, onSelect }) => {
  return (
    <CommandItem
      key={company.id}
      value={company.name}
      onSelect={() => onSelect(company)}
      className="py-2"
    >
      <div className="flex flex-col w-full">
        <div className="flex items-center">
          <Building2 className="mr-2 h-4 w-4" />
          <span className="font-medium">{company.name}</span>
        </div>
        
        {/* Always show the container for industry and website, even if empty */}
        <div className="grid grid-cols-1 gap-1 mt-1 text-xs text-muted-foreground">
          {company.industry ? (
            <div className="flex items-center">
              <Briefcase className="mr-1 h-3 w-3" />
              <span>{company.industry}</span>
            </div>
          ) : (
            <div className="flex items-center">
              <Briefcase className="mr-1 h-3 w-3 opacity-50" />
              <span className="italic opacity-50">No industry information</span>
            </div>
          )}
          
          {company.website ? (
            <div className="flex items-center">
              <Globe className="mr-1 h-3 w-3" />
              <span className="truncate">{company.website}</span>
            </div>
          ) : (
            <div className="flex items-center">
              <Globe className="mr-1 h-3 w-3 opacity-50" />
              <span className="italic opacity-50">No website available</span>
            </div>
          )}
          
          {/* Optional location information */}
          {company.location && (
            <div className="flex items-center">
              <MapPin className="mr-1 h-3 w-3" />
              <span>{company.location}</span>
            </div>
          )}
        </div>
      </div>
    </CommandItem>
  );
};

export default CompanyItem;
