
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from './components/theme-provider';
import { AuthProvider, useAuth } from './context/AuthContext';
import { JobProvider } from './context/JobContext';
import { Toaster } from "@/components/ui/toaster"
import { ToasterProvider } from "@/components/ui/toast"
import { toast } from "./components/ui/use-toast";

import Index from './pages/Index';
import KanbanBoard from './pages/KanbanBoard';
import TasksPage from './pages/TasksPage';
import Companies from './pages/Companies';
import Settings from './pages/Settings';
import ProfilePage from './pages/ProfilePage';
import Auth from './pages/Auth';
import NotFound from './pages/NotFound';
import Layout from './components/Layout';
import ApiDocs from './pages/ApiDocs';
import ExtensionDocs from './pages/ExtensionDocs';
import ReportsPage from './pages/ReportsPage';
import EnvDebug from './pages/EnvDebug';
import AdminRoles from "./pages/AdminRoles";

// If we're on the auth page and already authenticated, redirect to home
const AuthRedirect = () => {
  const { user, loading } = useAuth();
  const location = useLocation();
  
  if (loading) {
    return <div className="flex items-center justify-center h-screen">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
    </div>;
  }
  
  if (user) {
    // Redirect to the page they were trying to access or home
    const from = location.state?.from?.pathname || "/";
    return <Navigate to={from} replace />;
  }
  
  return <Auth />;
};

// Protected route component to ensure authentication
const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { user, loading } = useAuth();
  const location = useLocation();
  
  if (loading) {
    return <div className="flex items-center justify-center h-screen">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary" role="status"></div>
    </div>;
  }
  
  if (!user) {
    // Save the location they were trying to access for later redirect
    return <Navigate to="/auth" state={{ from: location }} replace data-testid="navigate" />;
  }
  
  return <>{children}</>;
};

// This component will prompt the user before processing code changes
const ConfirmationWrapper = ({ children }: { children: React.ReactNode }) => {
  // Confirmation dialog state
  const [showConfirm, setShowConfirm] = useState(false);
  const [pendingAction, setPendingAction] = useState<() => void | null>(null);
  
  // When component mounts, set the global flag
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // @ts-ignore - adding a custom property to window
      window.lovableConfirmChanges = true;
      
      // Add event listener for custom events
      window.addEventListener('lovableProcessChanges', (e: any) => {
        // Show confirmation dialog
        setShowConfirm(true);
        // Store the callback function
        if (e.detail && typeof e.detail.callback === 'function') {
          setPendingAction(() => e.detail.callback);
        }
      });
      
      // Cleanup on unmount
      return () => {
        // @ts-ignore - removing custom property
        delete window.lovableConfirmChanges;
        window.removeEventListener('lovableProcessChanges', () => {});
      };
    }
  }, []);
  
  // Handle confirmation
  const handleConfirm = () => {
    if (pendingAction) {
      pendingAction();
    }
    setShowConfirm(false);
    setPendingAction(null);
  };
  
  // Handle cancellation
  const handleCancel = () => {
    setShowConfirm(false);
    setPendingAction(null);
    toast({
      title: "Changes cancelled",
      description: "Your code changes were not applied.",
      variant: "default"
    });
  };
  
  return (
    <>
      {children}
      
      {/* Confirmation Dialog */}
      {showConfirm && (
        <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
          <div className="bg-background rounded-lg shadow-lg max-w-md w-full p-6">
            <h2 className="text-xl font-semibold mb-4">Confirm Code Changes</h2>
            <p className="mb-6">Are you sure you want to apply these code changes to your project?</p>
            <div className="flex justify-end space-x-4">
              <button 
                onClick={handleCancel}
                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-100 transition-colors"
              >
                Cancel
              </button>
              <button 
                onClick={handleConfirm}
                className="px-4 py-2 bg-primary text-primary-foreground rounded-md hover:bg-primary/90 transition-colors"
              >
                Apply Changes
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

function App() {
  return (
    <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
      <BrowserRouter>
        <AuthProvider>
          <JobProvider>
            <ToasterProvider>
              <ConfirmationWrapper>
                <Routes>
                  {/* Auth page is public */}
                  <Route path="/auth" element={<AuthRedirect />} />
                  
                  {/* All other routes are protected */}
                  <Route path="/" element={
                    <ProtectedRoute>
                      <Layout />
                    </ProtectedRoute>
                  }>
                    <Route index element={<Index />} />
                    <Route path="kanban" element={<KanbanBoard />} />
                    <Route path="board" element={<Navigate to="/kanban" replace />} />
                    <Route path="tasks" element={<TasksPage />} />
                    <Route path="companies" element={<Companies />} />
                    <Route path="reports" element={<ReportsPage />} />
                    <Route path="profile" element={<ProfilePage />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="api-docs" element={<ApiDocs />} />
                    <Route path="extension-docs" element={<ExtensionDocs />} />
                    <Route path="env-debug" element={<EnvDebug />} />
                    <Route path="admin/roles" element={<AdminRoles />} />
                  </Route>
                  
                  <Route path="*" element={<NotFound />} />
                </Routes>
                <Toaster />
              </ConfirmationWrapper>
            </ToasterProvider>
          </JobProvider>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
