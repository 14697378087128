
import { environment as devEnvironment } from './environment';
import { environment as prodEnvironment } from './environment.prod';
import { environment as qaEnvironment } from './environment.qa';

// Define the utility functions first

// Utility function to format version display consistently across the app
export const formatVersion = (version: string) => {
  if (!version) return 'v0.1.0';
  
  // Strip any environment suffix for display
  let displayVersion = version;
  
  // Remove environment suffixes if present
  if (displayVersion.includes('-dev') || displayVersion.includes('-qa')) {
    displayVersion = displayVersion.split('-')[0];
  }
  
  // Add 'v' prefix if not already present
  return displayVersion.startsWith('v') ? displayVersion : `v${displayVersion}`;
};

// Get environment name for display
export const getEnvironmentName = () => {
  // HIGHEST PRIORITY: Check explicit Netlify environment variables
  const netlifyContext = import.meta.env.NETLIFY_CONTEXT || import.meta.env.CONTEXT;
  if (netlifyContext) {
    console.log('[ENV DEBUG] Using Netlify context for environment: ', netlifyContext);
    if (netlifyContext === 'production') return 'production';
    if (netlifyContext === 'qa') return 'qa';
    if (netlifyContext === 'develop' || netlifyContext === 'deploy-preview' || netlifyContext === 'branch-deploy') {
      return 'development';
    }
  }
  
  // HIGH PRIORITY: Check the branch environment variable
  const branch = import.meta.env.BRANCH;
  if (branch) {
    console.log('[ENV DEBUG] Using branch for environment: ', branch);
    if (branch === 'main') return 'production';
    if (branch === 'qa') return 'qa';
    if (branch === 'develop') return 'development';
  }
  
  // MEDIUM PRIORITY: Check URL patterns for hosting environment
  const hostname = window.location.hostname;
  if (hostname.includes('dev-') || hostname.includes('dev.') || 
      hostname.includes('develop--') || hostname.includes('localhost') || 
      hostname.includes('127.0.0.1') || hostname.includes('preview--') ||
      hostname.includes('dev-ladderingup.netlify.app')) {
    console.log('[ENV DEBUG] Using hostname pattern for environment (development): ', hostname);
    return 'development';
  } else if (hostname.includes('qa-') || hostname.includes('qa.') || 
            hostname.includes('qa--') || hostname.includes('qa-ladderingup.netlify.app')) {
    console.log('[ENV DEBUG] Using hostname pattern for environment (qa): ', hostname);
    return 'qa';
  } else if (hostname.includes('ladderingup.com') || 
            hostname.includes('main--') || 
            hostname.includes('app.ladderingup.com') ||
            hostname.includes('ladderingup.netlify.app') && 
            !hostname.includes('dev-') && !hostname.includes('qa-')) {
    console.log('[ENV DEBUG] Using hostname pattern for environment (production): ', hostname);
    return 'production';
  }

  // LOW PRIORITY: Check Vite environment variable
  const viteEnv = import.meta.env.VITE_ENVIRONMENT;
  if (viteEnv) {
    console.log('[ENV DEBUG] Using VITE_ENVIRONMENT for environment: ', viteEnv);
    if (viteEnv === 'production') return 'production';
    if (viteEnv === 'qa') return 'qa';
    if (viteEnv === 'development') return 'development';
  }
  
  // LOWEST PRIORITY: Fallback to checking version suffix
  if (environment.version) {
    if (environment.production) return 'production';
    if (environment.version?.includes('-qa')) return 'qa';
    if (environment.version?.includes('-dev')) return 'development';
  }
  
  return 'development';
};

// Simplified environment detection using a single environment flag with suffix handling
const getEnvironment = () => {
  // Debug logs to help troubleshoot
  console.log('[ENV DEBUG] Current hostname:', window.location.hostname);
  console.log('[ENV DEBUG] Document last modified:', document.lastModified);
  console.log('[ENV DEBUG] Dev environment version:', devEnvironment.version);
  console.log('[ENV DEBUG] QA environment version:', qaEnvironment.version);
  console.log('[ENV DEBUG] Prod environment version:', prodEnvironment.version);
  console.log('[ENV DEBUG] Vite environment mode:', import.meta.env.MODE);
  console.log('[ENV DEBUG] VITE_ENVIRONMENT:', import.meta.env.VITE_ENVIRONMENT);
  console.log('[ENV DEBUG] NETLIFY_CONTEXT:', import.meta.env.NETLIFY_CONTEXT);
  console.log('[ENV DEBUG] CONTEXT:', import.meta.env.CONTEXT);
  console.log('[ENV DEBUG] BRANCH:', import.meta.env.BRANCH);
  
  let selectedEnv = null;
  let selectionReason = '';
  
  // HIGHEST PRIORITY: Netlify deploy context should take highest priority
  // This fixes the issue where builds were using the wrong environment file
  const netlifyContext = import.meta.env.NETLIFY_CONTEXT || import.meta.env.CONTEXT;
  if (netlifyContext) {
    console.log('[ENV DEBUG] Netlify context detected:', netlifyContext);
    if (netlifyContext === 'production') {
      selectedEnv = prodEnvironment;
      selectionReason = 'Netlify context is production';
    } else if (netlifyContext === 'qa' || netlifyContext === 'branch-qa') {
      selectedEnv = qaEnvironment;
      selectionReason = 'Netlify context is qa';
    } else if (netlifyContext === 'develop' || netlifyContext === 'branch-deploy' || netlifyContext === 'deploy-preview') {
      selectedEnv = devEnvironment;
      selectionReason = 'Netlify context is develop/branch-deploy/deploy-preview';
    }
  }
  
  // HIGH PRIORITY: Check the branch environment variable
  if (!selectedEnv) {
    const branch = import.meta.env.BRANCH;
    if (branch) {
      console.log('[ENV DEBUG] Branch detected:', branch);
      if (branch === 'main') {
        selectedEnv = prodEnvironment;
        selectionReason = 'Branch is main';
      } else if (branch === 'qa') {
        selectedEnv = qaEnvironment;
        selectionReason = 'Branch is qa';
      } else if (branch === 'develop') {
        selectedEnv = devEnvironment;
        selectionReason = 'Branch is develop';
      }
    }
  }
  
  // MEDIUM PRIORITY: Check URL patterns - this should take precedence over environment variables
  if (!selectedEnv) {
    const hostname = window.location.hostname;
    
    if (hostname.includes('dev-') || hostname.includes('dev.') || 
        hostname.includes('develop--') || hostname.includes('localhost') || 
        hostname.includes('127.0.0.1') || hostname.includes('preview--') ||
        hostname.includes('dev-ladderingup.netlify.app')) {
      selectedEnv = devEnvironment;
      selectionReason = `Hostname "${hostname}" matched development pattern`;
      console.log('[ENV DEBUG] Matched development hostname pattern');
    } else if (hostname.includes('qa-') || hostname.includes('qa.') || 
              hostname.includes('qa--') || hostname.includes('qa-ladderingup.netlify.app')) {
      selectedEnv = qaEnvironment;
      selectionReason = `Hostname "${hostname}" matched QA pattern`;
      console.log('[ENV DEBUG] Matched QA hostname pattern');
    } else if (hostname.includes('ladderingup.com') || 
              hostname.includes('main--') || 
              hostname.includes('app.ladderingup.com') ||
              (hostname.includes('ladderingup.netlify.app') && 
              !hostname.includes('dev-') && !hostname.includes('qa-'))) {
      selectedEnv = prodEnvironment;
      selectionReason = `Hostname "${hostname}" matched production pattern`;
      console.log('[ENV DEBUG] Matched production hostname pattern');
    }
  }
  
  // LOW PRIORITY: Check explicitly set VITE_ENVIRONMENT
  if (!selectedEnv && import.meta.env.VITE_ENVIRONMENT) {
    if (import.meta.env.VITE_ENVIRONMENT === 'production') {
      selectedEnv = prodEnvironment;
      selectionReason = 'VITE_ENVIRONMENT is production';
    } else if (import.meta.env.VITE_ENVIRONMENT === 'qa') {
      selectedEnv = qaEnvironment;
      selectionReason = 'VITE_ENVIRONMENT is qa';
    } else if (import.meta.env.VITE_ENVIRONMENT === 'development') {
      selectedEnv = devEnvironment;
      selectionReason = 'VITE_ENVIRONMENT is development';
    }
  }
  
  // LOW PRIORITY: Check for MODE from Vite
  if (!selectedEnv && import.meta.env.MODE) {
    if (import.meta.env.MODE === 'production') {
      selectedEnv = prodEnvironment;
      selectionReason = 'MODE is production';
    } else if (import.meta.env.MODE === 'qa') {
      selectedEnv = qaEnvironment;
      selectionReason = 'MODE is qa';
    } else if (import.meta.env.MODE === 'development') {
      selectedEnv = devEnvironment;
      selectionReason = 'MODE is development';
    }
  }
  
  // LOWEST PRIORITY: Examine version suffix
  if (!selectedEnv) {
    if (prodEnvironment.version && !prodEnvironment.version.includes('-')) {
      selectedEnv = prodEnvironment;
      selectionReason = 'Version has no suffix (production)';
    } else if (qaEnvironment.version && qaEnvironment.version.includes('-qa')) {
      selectedEnv = qaEnvironment;
      selectionReason = 'Version has -qa suffix';
    } else if (devEnvironment.version && devEnvironment.version.includes('-dev')) {
      selectedEnv = devEnvironment;
      selectionReason = 'Version has -dev suffix';
    }
  }
  
  // Final fallback to development environment
  if (!selectedEnv) {
    selectedEnv = devEnvironment;
    selectionReason = 'Final fallback to development';
  }
  
  // Log the selected environment for debugging
  console.log('[ENV DEBUG] Selected environment:', 
              selectedEnv.production ? 'production' : 
                (selectedEnv.version?.includes('-qa') ? 'qa' : 'development'));
  console.log('[ENV DEBUG] Selection reason:', selectionReason);
  console.log('[ENV DEBUG] Selected version:', selectedEnv.version);
  
  // Additional detailed debugging
  console.log('[ENV DEBUG] Additional environment checks:');
  console.log('[ENV DEBUG] document.location.hostname:', window.location.hostname);
  console.log('[ENV DEBUG] window.location.href:', window.location.href);
  console.log('[ENV DEBUG] Is hostname forcing production?', 
              window.location.hostname.includes('ladderingup.com') || 
              (window.location.hostname.includes('ladderingup.netlify.app') && 
              !window.location.hostname.includes('dev-') && 
              !window.location.hostname.includes('qa-')));
  
  return selectedEnv;
};

// Initialize the environment
export const environment = getEnvironment();

// Single log statement with key information for debugging
console.log(`[ENV] Using ${getEnvironmentName()} environment with version ${environment.version}`);
